import React, { useState } from 'react'
import { Row, Col, FormGroup, Form, HelpBlock } from 'react-bootstrap'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Prompt } from 'react-router'
import styled from '@emotion/styled'
import { useNotification } from '../../../providers/NotificationProvider'
import ImageDisplay from '../../../components/Image/ImageDisplay'
import { Card } from '../../../components/Card/Card'
import LoadingButton from '../../../components/CustomButton/LoadingButton'
import GuideButton from '../../../components/Link/GuideLink'
import FormInputs from '../../../components/FormInputs/FormInputs'
import FormLabel from '../../../components/FormInputs/FormLabel'
import SelectorForm from '../../../components/FormInputs/SelectorForm'
import FormInput from '../../../components/FormInputs/FormInput'
import ImageForm from '../../../components/FormInputs/ImageForm'
import Checkbox from '../../../components/CustomCheckbox/CustomCheckbox'
import {
  FormChangedAlertModal,
  ModalContentFormChangedList,
  Alert,
} from '../../../components/Modal/FormChangedAlertModal'
import { useApi, useBooleanState } from '../../../lib/hooks'
import { formatDateExceptTime } from '../../../lib/general'
import {
  PaymentBenefits,
  PaymentBenefitLabels,
} from '../../../constants/stampCard'
import { PaymentBenefit } from '../../../types/stampCard.d'
import { StampCardContent } from '../../../containers/entities/StampCardContentEntityContainer'
import SpecifyNumOfStampsQrSettingModal from './SpecifyNumOfStampsQrSettingModal'
import { useLoginContext } from '../../../providers/LoginContextProvider'

type StampCardContentForm = {
  max_stamp: number
  how_to_get_stamp: string
  how_to_uses: string
  connection_stamp: number
  check_in: string
  stamp_image: string | null
  common_stamp_image_url: string | null
  stamp_value: number
  special_check_in: string
  special_check_in_stamp: number
  payment_benefit: PaymentBenefit
  restrict_one_stamp_per_store: boolean
  custom_settings_enabled: boolean
  use_common_stamp_image: boolean
  hide_stamp_name: boolean
}

type StampCardContentParam = Partial<
  Omit<StampCardContentForm, 'check_in' | 'special_check_in'> & {
    check_in_enabled: boolean
    special_check_in_enabled: boolean
  }
>
type Props = {
  stampCardContent: StampCardContent
  disabled: boolean
  onUpdatedStampCardContent: () => void
  onAddedStampCardQrCode: () => void
  onDeletedStampCardQrCode: () => void
  onChangeUseCommonStampImage: (event: any) => void
}

const FieldLabels = {
  max_stamp: 'スタンプ数の上限',
  how_to_get_stamp: 'スタンプのため方',
  how_to_uses: 'スタンプカードの詳しい説明',
  connection_stamp: '初回登録付与スタンプ数',
  check_in: 'チェックインスタンプ',
  stamp_image: 'スタンプ画像',
  special_check_in: '特別チェックインスタンプ',
  special_check_in_stamp: '特別チェックインスタンプ数',
  payment_benefit: 'お会計スタンプ',
  stamp_value: 'お会計スタンプ数',
  restrict_one_stamp_per_store: '1店舗1回のみ(同一店舗不可)',
  use_common_stamp_image: '共通のスタンプ画像を設定する',
} as const

const StampCardFormView = ({
  stampCardContent,
  disabled,
  onUpdatedStampCardContent,
  onAddedStampCardQrCode,
  onDeletedStampCardQrCode,
  onChangeUseCommonStampImage,
}: Props): JSX.Element => {
  const { showSuccessNotification, showErrorNotification } = useNotification()
  const { currentStore } = useLoginContext()
  const isOwnerGroup = currentStore.store_type === 'owner_group'
  const isStoreGroup = currentStore.store_type === 'store_group'
  const isGroup = isOwnerGroup || isStoreGroup
  const watchStampCardStatus = stampCardContent.status as StampCardStatus
  const defaultValues = {
    max_stamp: stampCardContent.max_stamp,
    how_to_get_stamp: stampCardContent.how_to_get_stamp || '',
    how_to_uses: stampCardContent.how_to_uses || '',
    connection_stamp: stampCardContent.connection_stamp ?? 0,
    check_in: stampCardContent.check_in_enabled ? 'enabled' : 'disabled',
    stamp_image: stampCardContent.stamp_image_url,
    common_stamp_image_url: stampCardContent.stamp_image_url,
    stamp_value: stampCardContent.stamp_value || 0,
    special_check_in: stampCardContent.special_check_in_enabled
      ? 'enabled'
      : 'disabled',
    special_check_in_stamp: stampCardContent.special_check_in_stamp || 0,
    payment_benefit: stampCardContent.payment_benefit,
    restrict_one_stamp_per_store:
      stampCardContent.restrict_one_stamp_per_store || false,
    status: stampCardContent.status,
  }
  const methods = useForm<StampCardContentForm>({ defaultValues })
  const {
    register,
    handleSubmit,
    errors,
    watch,
    control,
    setValue,
    getValues,
    reset,
  } = methods
  const { isDirty, dirtyFields } = methods.formState
  const [
    isStatusChangeModalOpen,
    openStatusChangeModal,
    closeStatusChangeModal,
  ] = useBooleanState(false)
  const [isQrConfirmModalOpen, openQrConfirmModal, closeQrConfirmModal] =
    useBooleanState(false)
  const [deleteTargetQrCodeId, setDeleteTargetQrCodeId] = useState<
    number | null
  >(null)
  const [
    showSpecifyNumOfStampsQrSettingModal,
    setShowSpecifyNumOfStampsQrSettingModal,
  ] = useState(false)
  const [restrictOneStampPerStore, setRestrictOneStampPerStore] = useState(
    stampCardContent.restrict_one_stamp_per_store,
  )

  const [useCommonStampImage, setUseCommonStampImage] = useState(
    !!stampCardContent.use_common_stamp_image,
  )

  const { api, loading } = useApi()
  const watchStampCardContent = watch()
  const watchCheckInEnabled = watchStampCardContent.check_in === 'enabled'
  const watchSpecialCheckInEnabled =
    watchStampCardContent.special_check_in === 'enabled'

  const updateStampCardContent = async () => {
    const values = getValues()
    const updatedValues: StampCardContentParam = Object.keys(
      dirtyFields,
    ).reduce((result, key) => {
      switch (key) {
        case 'check_in':
          return { ...result, check_in_enabled: values.check_in === 'enabled' }
        case 'special_check_in':
          return {
            ...result,
            special_check_in_enabled: values.special_check_in === 'enabled',
          }
        default:
          return { ...result, [key]: values[key] }
      }
    }, {})

    const res = await api.patch('/stamp_card_contents', {
      stamp_card_content: updatedValues,
    })
    if (res) {
      showSuccessNotification('更新しました。')
      reset(values)
      onUpdatedStampCardContent()
    }

    closeStatusChangeModal()
  }

  const onError = () => {
    showErrorNotification('値が正しく入力されていません。')
  }

  const addStampCardQrCode = async (stamp: number | null) => {
    if (!window.confirm('スタンプ付与用QRコードを追加しますか')) {
      return
    }

    const query = stamp ? `?stamp=${stamp}` : ''
    api.post(`/stamp_card_content_qrcodes${query}`).then(() => {
      showSuccessNotification('追加しました。')
      onAddedStampCardQrCode()
    })
  }

  const deleteStampCardQrCode = async () => {
    api
      .delete(`/stamp_card_content_qrcodes/${deleteTargetQrCodeId}`)
      .then(() => {
        showSuccessNotification('削除しました。')
        onDeletedStampCardQrCode()
      })

    closeQrConfirmModal()
  }

  const warnings: string[] = []
  if (dirtyFields.check_in && !watchCheckInEnabled) {
    warnings.push(
      'チェックインスタンプを無効に設定すると、チェックイン時にスタンプ付与ができなくなります。',
    )
  }
  if (
    dirtyFields.special_check_in &&
    watchCheckInEnabled &&
    !watchSpecialCheckInEnabled
  ) {
    warnings.push(
      '特別チェックインスタンプを無効に設定すると、チェックイン時に通常のチェックインスタンプ（1スタンプ）のみ付与されます。',
    )
  }
  if (
    dirtyFields.payment_benefit &&
    watchStampCardContent.payment_benefit === 'disable'
  ) {
    warnings.push(
      'お会計スタンプを無効に設定すると、お会計時のスタンプ付与ができなくなります。',
    )
  }

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(openStatusChangeModal, onError)}>
          <Row style={{ marginBottom: 10 }}>
            <Col
              md={12}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <GuideButton
                label='スタンプカード'
                link='https://toypo.notion.site/47c9b76410b04feaaf14a446fb262ef0'
              />
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Card
                title='スタンプカード'
                content={
                  <>
                    <FormInputs
                      properties={[
                        {
                          name: 'max_stamp',
                          label: 'スタンプ数の上限',
                          ncol: 'col-md-4 col-sm-4 col-xs-8',
                          type: 'number',
                          bsClass: 'form-control',
                          disabled: disabled || loading,
                          inputRef: register({
                            valueAsNumber: true,
                            min: {
                              value: 1,
                              message: '1以上の数字を入力してください。',
                            },
                            max: {
                              value: 1000000,
                              message: '1000000以下の数字を入力してください。',
                            },
                            required: '数字を入力して下さい。',
                          }),
                          validationMessage: errors?.max_stamp?.message,
                          showChangeIndicator: Boolean(dirtyFields.max_stamp),
                        },
                        {
                          name: 'how_to_get_stamp',
                          label: 'スタンプのため方',
                          attachment: '任意',
                          ncol: 'col-lg-9 col-md-12',
                          placeholder:
                            '例: 店内でQRコードを読み取ってためましょう。スタッフへお気軽にお声がけください！',
                          bsClass: 'form-control',
                          disabled: disabled || loading,
                          inputRef: register,
                          validationMessage: errors?.how_to_get_stamp?.message,
                          showChangeIndicator: Boolean(
                            dirtyFields.how_to_get_stamp,
                          ),
                        },
                        {
                          name: 'how_to_uses',
                          label: 'スタンプカードの詳しい説明',
                          ncol: 'col-lg-9 col-md-12',
                          rows: '6',
                          componentClass: 'textarea',
                          placeholder:
                            '例: 1回のご来店につき、1スタンプ差し上げます！',
                          bsClass: 'form-control',
                          disabled: disabled || loading,
                          inputRef: register({ required: '入力して下さい。' }),
                          validationMessage: errors?.how_to_uses?.message,
                          showChangeIndicator: Boolean(dirtyFields.how_to_uses),
                        },
                        {
                          name: 'connection_stamp',
                          label: '初回登録付与スタンプ数',
                          ncol: 'col-md-4 col-sm-4 col-xs-8',
                          type: 'number',
                          bsClass: 'form-control',
                          disabled: disabled || loading,
                          inputRef: register({
                            valueAsNumber: true,
                            min: {
                              value: 0,
                              message: '0以上の数字を入力してください。',
                            },
                            max: {
                              value: 1000000,
                              message: '1000000以下の数字を入力してください。',
                            },
                            required: '数字を入力して下さい。',
                          }),
                          validationMessage: errors?.connection_stamp?.message,
                          showChangeIndicator: Boolean(
                            dirtyFields.connection_stamp,
                          ),
                        },
                      ]}
                    />

                    <Row>
                      <Col md={3} sm={3} xs={6}>
                        <FormGroup>
                          <SelectorForm
                            name='check_in'
                            inputRef={register}
                            disabled={disabled || loading}
                            label='チェックインスタンプ'
                            options={[
                              { label: '有効', value: 'enabled' },
                              { label: '無効', value: 'disabled' },
                            ]}
                            showChangeIndicator={Boolean(dirtyFields.check_in)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <StyledRow hide={!watchCheckInEnabled}>
                      <Col md={4} sm={5} xs={12}>
                        <FormGroup>
                          <SelectorForm
                            name='special_check_in'
                            disabled={disabled || loading}
                            inputRef={register}
                            label='特別チェックインスタンプ'
                            options={[
                              { label: '有効', value: 'enabled' },
                              { label: '無効', value: 'disabled' },
                            ]}
                            showChangeIndicator={Boolean(
                              dirtyFields.special_check_in,
                            )}
                          />
                        </FormGroup>
                      </Col>
                    </StyledRow>

                    <StyledRow
                      hide={!watchCheckInEnabled || !watchSpecialCheckInEnabled}
                    >
                      <Col className='flexForm'>
                        <FormInput
                          type='number'
                          bsSize='small'
                          bsClass='form-control'
                          name='special_check_in_stamp'
                          disabled={disabled || loading}
                          style={{ width: 100 }}
                          label='特別チェックインスタンプ数'
                          inputRef={register({
                            valueAsNumber: true,
                            validate: (v) => {
                              if (!watchSpecialCheckInEnabled) return true
                              return (
                                (1 <= Number(v) && Number(v) <= 1000000) ||
                                '1以上1000000以下の数字を入力してください'
                              )
                            },
                            required: '数字を入力して下さい。',
                          })}
                          validationMessage={
                            errors?.special_check_in_stamp?.message
                          }
                          unit='スタンプ'
                          showChangeIndicator={Boolean(
                            dirtyFields.special_check_in_stamp,
                          )}
                        />
                      </Col>
                    </StyledRow>

                    <Row>
                      <Col md={4} sm={5} xs={12}>
                        <FormGroup>
                          <SelectorForm
                            name='payment_benefit'
                            inputRef={register}
                            disabled={disabled || loading}
                            label='お会計スタンプ'
                            options={PaymentBenefits.map((paymentBenefit) => ({
                              label: PaymentBenefitLabels[paymentBenefit],
                              value: paymentBenefit,
                            }))}
                            showChangeIndicator={Boolean(
                              dirtyFields.payment_benefit,
                            )}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <StyledRow
                      hide={watchStampCardContent.payment_benefit === 'disable'}
                    >
                      <Col className='flexForm'>
                        <FormInput
                          type='number'
                          bsSize='small'
                          bsClass='form-control'
                          name='stamp_value'
                          label='お会計スタンプ数'
                          style={{ width: 100 }}
                          disabled={disabled || loading}
                          inputRef={register({
                            valueAsNumber: true,
                            validate: (v) => {
                              if (
                                watchStampCardContent.payment_benefit ===
                                'disable'
                              )
                                return true
                              return (
                                (1 <= Number(v) && Number(v) <= 1000000) ||
                                '1以上1000000以下の数字を入力してください'
                              )
                            },
                            required: '数字を入力して下さい。',
                          })}
                          validationMessage={errors?.stamp_value?.message}
                          unit='円で1スタンプ'
                          showChangeIndicator={Boolean(dirtyFields.stamp_value)}
                        />
                      </Col>
                    </StyledRow>

                    {!isGroup && (
                      <FormGroup bsSize='large'>
                        <Controller
                          control={control}
                          name='stamp_image'
                          render={({ value }) => (
                            <ImageForm
                              doCompress
                              doTrimming
                              canDelete
                              label='スタンプ画像'
                              value={value}
                              width={175}
                              height={175}
                              aspect={1 / 1}
                              validationMessage={errors?.stamp_image?.message}
                              onChange={(img) =>
                                setValue('stamp_image', img, {
                                  shouldDirty: true,
                                })
                              }
                              deleteImage={() =>
                                setValue('stamp_image', null, {
                                  shouldDirty: true,
                                })
                              }
                              showChangeIndicator={Boolean(
                                dirtyFields.stamp_image,
                              )}
                            />
                          )}
                        />
                        <HelpBlock className='text-muted'>
                          画像は縦横比1:1で表示されます。
                        </HelpBlock>
                      </FormGroup>
                    )}

                    <FormGroup bsSize='large'>
                      <FormLabel label='スタンプ付与用QRコード' />
                      <StyledQrCodeContainer>
                        {stampCardContent?.stamp_qrcodes.map((stampQrcode) => (
                          <ImageDisplay
                            key={stampQrcode.id}
                            label={`作成日：${formatDateExceptTime(
                              stampQrcode.created_at,
                            )}\n付与スタンプ：${stampQrcode.stamp || '入力'}`}
                            href={stampQrcode.image_url}
                            altMessage='スタンプ付与用QRコード'
                            image={stampQrcode.image_url}
                            filename={
                              stampQrcode.stamp
                                ? `スタンプ付与用QRコード(${stampQrcode.stamp}スタンプ).png`
                                : `スタンプ付与用QRコード(入力).png`
                            }
                            width={120}
                            height={120}
                            onClickDeleteButton={(e) => {
                              e.preventDefault()
                              setDeleteTargetQrCodeId(stampQrcode.id)
                              openQrConfirmModal()
                            }}
                          />
                        ))}

                        <ImageDisplay
                          width={120}
                          height={120}
                          onClickCreateButton={() =>
                            setShowSpecifyNumOfStampsQrSettingModal(true)
                          }
                        />
                      </StyledQrCodeContainer>
                    </FormGroup>

                    <FormGroup bsSize='large'>
                      <Row>
                        <Col md={4} sm={5} xs={12}>
                          <FormGroup>
                            {isGroup && (
                              <Controller
                                control={control}
                                name='use_common_stamp_image'
                                defaultValue={useCommonStampImage}
                                render={({ onChange, value }) => (
                                  <Checkbox
                                    inline
                                    label='共通のスタンプ画像を設定する'
                                    checked={value}
                                    onChange={(e) => {
                                      onChange(e.target.checked)
                                      setUseCommonStampImage(e.target.checked)
                                      setValue('stamp_image', null, {})
                                      onChangeUseCommonStampImage(
                                        e.target.checked,
                                      )
                                    }}
                                    showChangeIndicator={Boolean(
                                      dirtyFields.use_common_stamp_image,
                                    )}
                                  />
                                )}
                              />
                            )}

                            {useCommonStampImage && (
                              <Controller
                                control={control}
                                name='stamp_image'
                                render={({ value }) => (
                                  <ImageForm
                                    doTrimming
                                    canDelete
                                    value={value}
                                    width={264}
                                    height={160}
                                    aspect={1 / 1}
                                    validationMessage={
                                      errors?.stamp_image?.message
                                    }
                                    onChange={(img) =>
                                      setValue('stamp_image', img, {
                                        shouldDirty: true,
                                      })
                                    }
                                    deleteImage={() =>
                                      setValue('stamp_image', null, {
                                        shouldDirty: true,
                                      })
                                    }
                                    showChangeIndicator={Boolean(
                                      dirtyFields.stamp_image,
                                    )}
                                  />
                                )}
                              />
                            )}

                            {isGroup && (
                              <Controller
                                control={control}
                                name='restrict_one_stamp_per_store'
                                defaultValue={restrictOneStampPerStore}
                                render={({ onChange, value }) => (
                                  <FormGroup>
                                    <Checkbox
                                      inline
                                      label='1店舗1回のみ(同一店舗不可)'
                                      checked={value}
                                      disabled={
                                        disabled ||
                                        loading ||
                                        watchStampCardStatus === 'open'
                                      }
                                      onChange={(e) => {
                                        onChange(e.target.checked)
                                        setRestrictOneStampPerStore(
                                          e.target.checked,
                                        )
                                      }}
                                      showChangeIndicator={Boolean(
                                        dirtyFields.restrict_one_stamp_per_store,
                                      )}
                                    />
                                    <StyledHelperContainer>
                                      <HelpBlock className='text-muted'>
                                        公開後に変更することはできません。
                                      </HelpBlock>
                                    </StyledHelperContainer>
                                  </FormGroup>
                                )}
                              />
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                    </FormGroup>

                    <LoadingButton
                      type='submit'
                      label='更新する'
                      loadingLabel='更新中...'
                      color='info'
                      fill
                      pullRight
                      disabled={!isDirty}
                      loading={loading}
                    />
                    <div className='clearfix' />
                  </>
                }
              />
            </Col>
          </Row>
        </Form>
      </FormProvider>

      <Prompt
        when={isDirty}
        message='行った変更が保存されていない可能性があります。このページを離れますか？'
      />

      <FormChangedAlertModal
        show={isQrConfirmModalOpen}
        title='スタンプ付与用QRコードを削除'
        onSubmit={deleteStampCardQrCode}
        submitButtonLabel='削除する'
        onCancel={closeQrConfirmModal}
        disabled={disabled || loading}
        displayStatus='danger'
        needAgreement
      >
        スタンプカード付与用のQRコードを削除します。本当に削除してよろしいですか？
        <Alert severity='danger'>
          <ul>
            <li>
              QRコードを削除するとユーザーがQRコードを読み取れなくなります。
            </li>
            <li>QRコードを復元することはできません。</li>
          </ul>
        </Alert>
      </FormChangedAlertModal>

      <FormChangedAlertModal
        show={isStatusChangeModalOpen}
        title='スタンプカードを更新'
        onSubmit={updateStampCardContent}
        onCancel={closeStatusChangeModal}
        disabled={disabled || loading}
      >
        スタンプカードの内容を更新してよろしいですか？
        <ModalContentFormChangedList
          changedProperties={Object.entries(FieldLabels)
            .map(([key, label]) => (dirtyFields[key] ? label : ''))
            .filter((v) => v)}
        />
        {warnings.length > 0 && (
          <Alert severity='warning'>
            <ul>
              {warnings.map((warning) => (
                <li>{warning}</li>
              ))}
            </ul>
          </Alert>
        )}
      </FormChangedAlertModal>

      <SpecifyNumOfStampsQrSettingModal
        show={showSpecifyNumOfStampsQrSettingModal}
        onHide={() => setShowSpecifyNumOfStampsQrSettingModal(false)}
        onClickCreateStampCardContentQrcode={addStampCardQrCode}
      />
    </>
  )
}

const StyledRow = styled(Row)<{ hide: boolean }>(({ hide }) => ({
  display: hide ? 'none' : 'block',
}))

const StyledQrCodeContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
})

const StyledHelperContainer = styled('div')({
  marginLeft: 32,
  marginTop: -16,
})

export default StampCardFormView
