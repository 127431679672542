import React, { useEffect, useState, useCallback } from 'react'
import {
  Grid,
  Row,
  Col,
  FormGroup,
  FormControl,
  Form,
  HelpBlock,
} from 'react-bootstrap'
import {
  Controller,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form'
import { Prompt } from 'react-router'
import { useHistory, useLocation } from 'react-router-dom'
import { Backdrop, CircularProgress } from '@mui/material'
import Button from '@mui/material/Button'
import { AxiosError } from 'axios'
import { BarcodeProps } from 'react-barcode'
import { DEFAULTIMAGE } from '../../lib/defaultImage'
import { getObjectDiff } from '../../lib/general'
import { useQuery, useApi, useBooleanState } from '../../lib/hooks'
import { useLoginContext } from '../../providers/LoginContextProvider'
import { useNotification } from '../../providers/NotificationProvider'
import SelectorForm from '../../components/FormInputs/SelectorForm'
import { Card } from '../../components/Card/Card'
import Checkbox from '../../components/CustomCheckbox/CustomCheckbox'
import CustomButton from '../../components/CustomButton/CustomButton'
import LoadingButton from '../../components/CustomButton/LoadingButton'
import FormInputs from '../../components/FormInputs/FormInputs'
import { ImageCard } from '../../components/Image/ImageInput'
import ImageForm from '../../components/FormInputs/ImageForm'
import FormLabel from '../../components/FormInputs/FormLabel'
import SwitchForm from '../../components/FormInputs/SwitchForm'
import PrivilegeCouponContentEntityContainer, {
  PrivilegeCouponType,
  CouponStatus,
  CouponStatusType,
  PrivilegeCouponCategoryType,
  privilegeCouponStatusToJp,
  serviceTypeToJp,
} from '../../containers/entities/PrivilegeCouponContentEntityContainer'
import DiscountFormCard from './parts/DiscountFormCard'
import QuestionnaireFormCard from './parts/QuestionnaireFormCard'
import PrivilegeCouponFormActiveSettingsCard from './parts/PrivilegeCouponFormActiveSettingsCard'
import CouponQuestionnaireForm from './parts/CouponQuestionnaireForm'
import FormInput from '../../components/FormInputs/FormInput'
import {
  FormChangedAlertModal,
  Alert,
} from '../../components/Modal/FormChangedAlertModal'
import CouponUsageMethodForm from './parts/CouponUsageMethodForm'
import CouponExpirationForm from './parts/CouponExpirationForm'

type LocationState = {
  maxStamp?: number
  privilegeCoupon: PrivilegeCouponType
  rankId?: number
  rankingId?: number
  rankingRewardId?: number
  startRank?: number
  endRank?: number
}

const PrivilegeCouponFormView = () => {
  const { hasFunction, currentStore } = useLoginContext()
  const history = useHistory()
  const location = useLocation<LocationState>()
  const { showSuccessNotification, showErrorNotification } = useNotification()
  const query = useQuery()
  const api = useApi()
  const { api: rankingApi } = useApi()
  const questionnaireId = Number(query.get('questionnaire_id'))
  const editMode = location.pathname.indexOf('edit') !== -1
  const couponMode = location.pathname.indexOf('coupon') !== -1
  const stampCardMode = location.pathname.indexOf('stamp') !== -1
  const pointMode = location.pathname.indexOf('point') !== -1
  const rankMode = location.pathname.indexOf('rank') !== -1
  const rankingMode = location.pathname.indexOf('reward') !== -1
  const maxStamp = location.state?.maxStamp
  const rankId = location.state?.rankId
  const rankingId = Number(query.get('ranking_id'))
  const rankingRewardId = location.state?.rankingRewardId
  const startRank = location.state?.startRank
  const endRank = location.state?.endRank
  const [coupon, setCoupon] = useState(location.state?.privilegeCoupon)
  const [
    isCouponDeleteModalOpen,
    openCouponDeleteModal,
    closeCouponDeleteModal,
  ] = useBooleanState(false)

  let category: PrivilegeCouponCategoryType
  let title = ''

  if (couponMode) {
    category = 'code'
    title = '特殊配信クーポン'
  } else if (stampCardMode) {
    category = 'stamp'
    title = '特典'
  } else if (pointMode) {
    category = 'point'
    title = 'ポイント交換クーポン'
  } else if (rankMode) {
    category = 'rank'
    title = 'ランクアップクーポン'
  } else if (rankingMode) {
    category = 'ranking'
    title = 'ランキング特典'
  }

  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [isBackdropLoading, setIsBackdropLoading] = useState(false)
  const [isShowDiscountFormCard, setIsShowDiscountFormCard] = useState(
    !!coupon?.service_type,
  )
  const [isShowCouponUsageMethodFormCard, setIsShowCouponUsageMethodFormCard] =
    useState(Boolean(coupon?.user_content_usage_method))

  const privilegeCouponEntityContainer =
    PrivilegeCouponContentEntityContainer.useContainer()

  const { serviceTypes } = privilegeCouponEntityContainer.constant

  const { createPrivilegeCoupon, updatePrivilegeCoupon } =
    privilegeCouponEntityContainer.logic

  const defaultValues = generateDefaultValues(
    coupon,
    startRank,
    endRank,
    category,
    questionnaireId,
    rankId,
    currentStore.customer_unit_price,
  )

  const methods = useForm<{ coupon: PrivilegeCouponType }>({ defaultValues })

  const {
    register,
    unregister,
    handleSubmit,
    errors,
    control,
    getValues,
    setValue,
    formState: { isDirty, dirtyFields },
    reset,
    watch,
    trigger,
  } = methods

  useEffect(() => {
    reset(
      generateDefaultValues(
        coupon,
        startRank,
        endRank,
        category,
        questionnaireId,
        rankId,
        currentStore.customer_unit_price,
      ),
    )
  }, [
    reset,
    coupon,
    startRank,
    endRank,
    category,
    questionnaireId,
    rankId,
    currentStore.customer_unit_price,
  ])

  const watchCategory = watch('coupon.category')
  const watchServiceType = watch('coupon.service_type')
  const watchCoupon = watch('coupon')

  const processFormData = useCallback(
    (data) => {
      const processedData = data

      // imageを変更してない場合keyを削除
      if (coupon?.image_url === processedData?.image) {
        delete processedData.image
      }

      // has_usage_limitを変更してない場合keyを削除（文字列に変わるため）
      if (
        coupon?.has_usage_limit === JSON.parse(processedData?.has_usage_limit)
      ) {
        delete processedData.has_usage_limit
      }

      // published_max_numberを変更してない場合keyを削除(入力していないとNaNになる)
      if (
        !coupon?.published_max_number &&
        Number.isNaN(processedData?.published_max_number)
      ) {
        delete processedData.published_max_number
      }

      // threshold_pointを変更してない場合keyを削除(入力していないとNaNになる)
      if (
        !coupon?.threshold_point &&
        Number.isNaN(processedData?.threshold_point)
      ) {
        delete processedData.threshold_point
      }

      // 割引のチェックがついてない場合
      if (coupon && !isShowDiscountFormCard) {
        processedData.service_type = null
        processedData.service_value = null
      }

      // 有効期限を設定しない場合
      if (processedData.is_unlimited) {
        processedData.expiration_day = null
        processedData.expiration_time = null
      }

      // 編集時に有効設定カード内のみで変更可
      if (editMode) {
        delete processedData.status
        // 作成時にのみ使用するパラメータなので削除
        delete processedData.rank_id
      }

      return processedData
    },
    [coupon, editMode, isShowDiscountFormCard],
  )

  const onClickCreateButton = async (
    data: PrivilegeCouponType,
  ): Promise<void> => {
    try {
      const response = await createPrivilegeCoupon(data)
      showSuccessNotification(`${title}を作成しました。`)
      if (rankingMode) {
        const coupon_content_id = response.data?.id
        rankingApi.post(`/rankings/${rankingId}/ranking_rewards`, {
          ranking_reward: {
            rewardable_type: 'PresentCouponContent',
            rewardable_id: coupon_content_id,
            start_rank: data.start_rank,
            end_rank: data.end_rank,
          },
        })
      }
      reset({ coupon: response.data })
      history.goBack()
    } catch (error) {
      showSuccessNotification((error as AxiosError).message)
    }
  }

  const onClickUpdateButton = async (data): Promise<void> => {
    const params = getObjectDiff(coupon, data)
    if (Object.keys(params).length === 0) {
      // データが変更されていない
      showSuccessNotification('保存する変更がありません。')
      return
    }

    try {
      const response = await updatePrivilegeCoupon({ id: coupon.id, params })
      showSuccessNotification(`${title}を更新しました。`)
      if (rankingMode) {
        const coupon_content_id = response.data?.id
        rankingApi.patch(
          `/rankings/${rankingId}/ranking_rewards/${rankingRewardId}`,
          {
            ranking_reward: {
              rewardable_type: 'PresentCouponContent',
              rewardable_id: coupon_content_id,
              start_rank: data.start_rank,
              end_rank: data.end_rank,
            },
          },
        )
      }
      reset({ coupon: response.data })
      history.goBack()
    } catch (error) {
      showSuccessNotification((error as AxiosError).message)
    }
  }

  const handleClickDeleteButton = () => {
    if (coupon.published_number === 0) {
      if (!window.confirm(`この${title}を削除しますか？`)) return
      deleteCouponContent()
    } else {
      openCouponDeleteModal()
    }
  }

  const deleteCouponContent = async (): Promise<void> => {
    const res = await api.api.delete(`/coupon_contents/${coupon.id}`)
    if (!res) return

    showSuccessNotification(`${title}を削除しました。`)
    reset()
    history.goBack()
  }

  const onClickPublishButton = async (
    status: CouponStatusType,
  ): Promise<void> => {
    if (
      stampCardMode &&
      maxStamp &&
      coupon.threshold_point &&
      coupon.threshold_point > maxStamp
    ) {
      showSuccessNotification(
        'スタンプ数の上限を超えるポイントを持つ特典はスタンプカードに反映できません',
      )
      return
    }

    const isDirtyExceptStatus = !!Object.keys(dirtyFields.coupon || {}).filter(
      (key) => key !== 'status',
    ).length

    const isSave = isDirtyExceptStatus
      ? window.confirm(
          '行った変更が保存されていない可能性があります。変更内容を保存しますか？',
        )
      : false
    if (isSave && !(await trigger())) {
      showErrorNotification('値が正しく入力されていません。')
      return
    }

    const isPublish = window.confirm(
      `${privilegeCouponStatusToJp(status)}にしますか？`,
    )
    if (!isSave && !isPublish) {
      return
    }

    const data = getValues()
    const params = isSave
      ? {
          ...getObjectDiff(coupon, processFormData(data.coupon)),
          status,
        }
      : { status }

    setIsBackdropLoading(true)
    try {
      const res = await updatePrivilegeCoupon({
        id: coupon?.id,
        params,
      })
      setCoupon(res?.data)
      reset({ coupon: { ...res.data, image: res.data.image_url } })
      history.goBack()
      showSuccessNotification('更新しました。')
    } catch (error) {
      showSuccessNotification((error as AxiosError).message)
    } finally {
      setIsBackdropLoading(false)
    }
  }

  const onSubmit: SubmitHandler<{
    coupon: PrivilegeCouponType & {
      image: string | null
    }
  }> = (data) => {
    const formData = processFormData(data.coupon)
    setIsButtonLoading(true)
    if (editMode) {
      onClickUpdateButton(formData).finally(() => setIsButtonLoading(false))
    } else {
      onClickCreateButton(formData).finally(() => setIsButtonLoading(false))
    }
  }
  const onError: SubmitErrorHandler<{
    coupon: PrivilegeCouponType & {
      image: string | null
    }
  }> = () => {
    showErrorNotification('値が正しく入力されていません。')
  }

  // custom register
  useEffect(() => {
    register({ name: 'coupon.category' })
    register({ name: 'coupon.status' })
    register({ name: 'coupon.rank_id' })
    register({ name: 'coupon.questionnaire_id' })

    return () => {
      unregister(['coupon.category'])
      unregister(['coupon.status'])
      unregister(['coupon.rank_id'])
      unregister(['coupon.questionnaire_id'])
    }
  }, [register, unregister])

  type BarcodeFormat = NonNullable<BarcodeProps['format']>

  return (
    <FormProvider {...methods}>
      <div className='content'>
        <Grid fluid>
          <Form onSubmit={handleSubmit(onSubmit, onError)}>
            <Row style={{ marginBottom: 10 }}>
              <Col md={10}>
                {editMode && (
                  <PrivilegeCouponFormActiveSettingsCard
                    editMode={editMode}
                    coupon={coupon}
                    onClickPublishButton={onClickPublishButton}
                    stampCardMode={stampCardMode}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col md={10}>
                <Card
                  title={editMode ? `${title}編集` : `${title}作成`}
                  content={
                    <>
                      <FormInputs
                        properties={[
                          {
                            name: 'coupon.benefit',
                            label: 'タイトル',
                            ncol: 'col-md-9',
                            type: 'text',
                            bsClass: 'form-control',
                            inputRef: register({
                              required: 'タイトルを入力してください。',
                            }),
                            validationMessage: errors?.coupon?.benefit?.message,
                          },
                          {
                            name: 'coupon.body',
                            label: 'サービス内容',
                            ncol: 'col-md-12',
                            attachment: '任意',
                            rows: '6',
                            componentClass: 'textarea',
                            bsClass: 'form-control',
                            inputRef: register,
                            validationMessage: errors?.coupon?.body?.message,
                          },
                          {
                            name: 'coupon.terms',
                            label: '利用条件',
                            ncol: 'col-md-12',
                            rows: '6',
                            componentClass: 'textarea',
                            bsClass: 'form-control',
                            inputRef: register({
                              required: '利用条件を入力してください。',
                            }),
                            validationMessage: errors?.coupon?.terms?.message,
                          },
                          {
                            name: 'coupon.important_notes',
                            label: '利用時の注意事項',
                            ncol: 'col-md-12',
                            attachment: '任意',
                            rows: '6',
                            componentClass: 'textarea',
                            bsClass: 'form-control',
                            inputRef: register,
                          },
                        ]}
                      />

                      <FormGroup bsSize='large'>
                        <Controller
                          control={control}
                          name='coupon.image'
                          render={({ value }) => (
                            <ImageForm
                              doTrimming
                              label='画像'
                              value={value}
                              onChange={(img) => {
                                setValue('coupon.image', img, {
                                  shouldDirty: true,
                                })
                              }}
                              validationMessage={errors?.coupon?.image?.message}
                            />
                          )}
                        />
                        <HelpBlock className='text-muted'>
                          画像は縦横比16:9で表示されます。
                        </HelpBlock>
                      </FormGroup>

                      {/* 利用回数 */}
                      <Row>
                        <Col md={3} sm={3} xs={6}>
                          <FormGroup bsSize='large'>
                            <SelectorForm
                              name='coupon.has_usage_limit'
                              inputRef={register}
                              label='利用回数'
                              options={[
                                {
                                  label: '1人1回まで',
                                  value: true,
                                },
                                {
                                  label: '何度でも可',
                                  value: false,
                                },
                              ]}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {couponMode && (
                        <>
                          <CouponElements
                            register={register}
                            errors={errors}
                            coupon={coupon}
                          />
                        </>
                      )}

                      {stampCardMode && (
                        <StampCardElements
                          register={register}
                          errors={errors}
                          maxStamp={maxStamp}
                        />
                      )}

                      {pointMode && (
                        <PointCardElements
                          register={register}
                          errors={errors}
                          setValue={setValue}
                          category={watchCategory}
                        />
                      )}

                      {rankingMode && (
                        <RankingRewardElements
                          register={register}
                          errors={errors}
                        />
                      )}

                      {/* 有効期限設定 */}
                      <CouponExpirationForm
                        name='coupon'
                        editMode={editMode}
                        isAuto
                      />

                      {/* オプション */}
                      <Row>
                        <Col md={12}>
                          <FormGroup>
                            <FormLabel label='オプション' attachment='任意' />
                            {[
                              {
                                name: 'discount',
                                label: '割引設定',
                                disabled:
                                  coupon?.status === CouponStatus.Publish,
                                checked: isShowDiscountFormCard,
                                onChange: (e): void => {
                                  setIsShowDiscountFormCard(e.target.checked)
                                },
                              },
                              {
                                name: 'useCoupon',
                                label: '利用方法の設定',
                                checked: isShowCouponUsageMethodFormCard,
                                onChange: (e): void => {
                                  setIsShowCouponUsageMethodFormCard(
                                    e.target.checked,
                                  )
                                },
                              },
                              couponMode && {
                                label:
                                  '同一ユーザーによる複数回のクーポン獲得を許可する',
                                name: 'coupon.multiple_get_enabled',
                                inputRef: register,
                              },
                            ].map((item, i) => {
                              if (!item) return null
                              return (
                                <Row key={item.name}>
                                  <Col sm={12}>
                                    <Checkbox number={i + 1} inline {...item} />
                                  </Col>
                                </Row>
                              )
                            })}
                          </FormGroup>
                        </Col>
                      </Row>

                      <div className='clearfix' />
                    </>
                  }
                />

                {isShowDiscountFormCard && (
                  <DiscountFormCard
                    resourceName='coupon'
                    serviceTypes={serviceTypes}
                    serviceTypeToJp={serviceTypeToJp}
                    register={register}
                    serviceType={watchServiceType}
                    errors={errors}
                    active={coupon?.status === CouponStatus.Publish}
                  />
                )}

                {isShowCouponUsageMethodFormCard && (
                  <Card
                    title='利用方法の設定'
                    content={
                      <CouponUsageMethodForm
                        userContentUsageMethod={
                          watchCoupon.user_content_usage_method
                        }
                        autoUseTimerMinutes={watchCoupon.auto_use_timer_minutes}
                        barcodeType={watchCoupon.barcode_type as BarcodeFormat}
                        couponCode={watchCoupon.coupon_code}
                      />
                    }
                  />
                )}

                <Row>
                  <Col md={12}>
                    {!editMode && (
                      <PrivilegeCouponFormActiveSettingsCard
                        editMode={editMode}
                        coupon={coupon}
                        onClickPublishButton={onClickPublishButton}
                        stampCardMode={stampCardMode}
                      />
                    )}
                  </Col>
                </Row>

                {hasFunction('questionnaire') && couponMode && (
                  <Row>
                    <Col md={12}>
                      <Card
                        title='アンケート回答クーポン'
                        content={
                          <CouponQuestionnaireForm
                            questionnaireId={
                              watchCoupon.questionnaire_id || null
                            }
                            onChange={(questionnaireId) => {
                              setValue(
                                'coupon.questionnaire_id',
                                questionnaireId,
                                { shouldDirty: true },
                              )
                            }}
                          />
                        }
                      />
                    </Col>
                  </Row>
                )}

                {coupon?.questionnaire_url && (
                  <Row>
                    <Col md={12}>
                      <QuestionnaireFormCard />
                    </Col>
                  </Row>
                )}

                <Card
                  title='クーポン利用による売上設定'
                  category='売上効果の集計を設定金額 x 利用数で計算して表示します'
                  content={
                    <Row>
                      <FormInput
                        name='coupon.customer_unit_price'
                        unit='円'
                        ncol='col-md-3 col-sm-3 col-xs-8'
                        type='number'
                        bsClass='form-control'
                        inputRef={register({
                          required:
                            'クーポン利用による売上設定を入力してください。',
                          valueAsNumber: true,
                          min: {
                            value: 0,
                            message: '0 円以上の金額を設定してください。',
                          },
                        })}
                        validationMessage={
                          errors?.coupon?.customer_unit_price?.message
                        }
                        showChangeIndicator={
                          editMode &&
                          Boolean(dirtyFields.coupon?.customer_unit_price)
                        }
                        style={{ minWidth: 100 }}
                      />
                    </Row>
                  }
                />

                <Card
                  title='目的・振り返り'
                  category='施策の振り返りや評価のためのメモを残すことができます。このメモはユーザーからは見えません。'
                  content={
                    <Row>
                      <Col md={12}>
                        <FormInput
                          name='coupon.memo'
                          label='メモ'
                          attachment='任意'
                          placeholder='例：女性向けに配信、雨の日に配信など'
                          ncol='col-md-12'
                          rows='5'
                          componentClass='textarea'
                          bsClass='form-control'
                          inputRef={register}
                          validationMessage={errors?.coupon?.memo?.message}
                          showChangeIndicator={
                            editMode && Boolean(dirtyFields.coupon?.memo)
                          }
                        />
                      </Col>
                    </Row>
                  }
                />

                <Row style={{ marginBottom: 30 }}>
                  <Col xs={6} md={5} mdOffset={1}>
                    <CustomButton block fill onClick={history.goBack}>
                      <b>もどる</b>
                    </CustomButton>
                  </Col>
                  <Col xs={6} md={5}>
                    <LoadingButton
                      type='submit'
                      label={editMode ? '編集する' : '作成する'}
                      loadingLabel={editMode ? '編集中...' : '作成中...'}
                      color='info'
                      block
                      fill
                      disabled={!isDirty}
                      loading={isButtonLoading}
                    />
                  </Col>
                </Row>

                {editMode && (
                  <Row>
                    <Col
                      xs={8}
                      xsOffset={2}
                      md={6}
                      mdOffset={3}
                      style={{ textAlign: 'center' }}
                    >
                      <Button
                        color='danger'
                        onClick={handleClickDeleteButton}
                        disabled={api.loading}
                      >
                        削除する
                      </Button>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Form>
        </Grid>
        <Prompt
          when={isDirty}
          message='行った変更が保存されていない可能性があります。このページを離れますか？'
        />
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: (theme): number => theme.zIndex.drawer + 1,
          }}
          open={isBackdropLoading}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      </div>

      <FormChangedAlertModal
        show={isCouponDeleteModalOpen}
        title='クーポンを削除する'
        submitButtonLabel='削除する'
        danger
        disabled={api.loading}
        onSubmit={deleteCouponContent}
        needAgreement
        onCancel={closeCouponDeleteModal}
      >
        クーポンを削除してよろしいですか？
        <Alert severity='danger'>
          <ul>
            <li>ユーザーに配信されたクーポンは削除されます。</li>
            <li>削除されたクーポンはトイポアプリから表示されなくなります。</li>
          </ul>
        </Alert>
      </FormChangedAlertModal>
    </FormProvider>
  )
}

const StampCardElements = ({ register, errors, maxStamp }): JSX.Element => (
  <>
    <FormInputs
      properties={[
        {
          name: 'coupon.threshold_point',
          label: '必要スタンプ数',
          ncol: 'col-md-3 col-sm-3 col-xs-8',
          type: 'number',
          bsClass: 'form-control',
          inputRef: register({
            required: '必要スタンプ数を入力してください',
            valueAsNumber: true,
            min: {
              value: 1,
              message: '1以上の数字を入力してください。',
            },
            max: {
              value: maxStamp,
              message: `スタンプ数上限${maxStamp}以下の数字を入力してください。`,
            },
          }),
          validationMessage: errors?.coupon?.threshold_point?.message,
        },
      ]}
    />
  </>
)

const CouponElements = ({ register, errors, coupon }): JSX.Element => (
  <>
    <FormInputs
      properties={[
        {
          name: 'coupon.published_max_number',
          label: '最大配信枚数',
          attachment: '任意',
          ncol: 'col-md-3 col-sm-3 col-xs-8',
          type: 'number',
          bsClass: 'form-control',
          inputRef: register({
            valueAsNumber: true,
            min: {
              value: 1,
              message: '1以上の数字を入力してください。',
            },
            max: {
              value: 1000000,
              message: '1000000以下の数字を入力してください。',
            },
          }),
          validationMessage: errors?.coupon?.published_max_number?.message,
        },
      ]}
    />

    {/* QRコード */}
    {coupon?.qrcode_url && (
      <Row>
        <Col xs={12} sm={12}>
          <FormLabel label='QRコード' />
          <div>
            <a
              href={coupon.qrcode_url}
              target='_blank'
              rel='noopener noreferrer'
            >
              <ImageCard
                src={coupon.qrcode_url}
                altMessage='クーポン'
                width={100}
                height={100}
              />
            </a>
          </div>
        </Col>
      </Row>
    )}
  </>
)

const PointCardElements = ({
  register,
  errors,
  setValue,
  category,
}): JSX.Element => (
  <>
    <FormInputs
      properties={[
        {
          name: 'coupon.threshold_point',
          label: '必要ポイント数',
          ncol: 'col-md-3 col-sm-3 col-xs-8',
          type: 'number',
          bsClass: 'form-control',
          inputRef: register({
            valueAsNumber: true,
            required: '必要ポイント数を入力してください',
            min: {
              value: 1,
              message: '1以上の数字を入力してください',
            },
            max: {
              value: 1000000,
              message: '1000000以下の数字を入力してください。',
            },
          }),
          validationMessage: errors?.coupon?.threshold_point?.message,
        },
      ]}
    />

    <SwitchForm
      label='自動交換'
      tooltipText='有効にするとユーザーが貯めたポイントが自動でクーポンに交換されます。'
      checked={category === 'auto_point'}
      onChange={(): void => {
        if (category === 'point') {
          setValue('coupon.category', 'auto_point', {
            shouldDirty: true,
          })
        } else {
          setValue('coupon.category', 'point', {
            shouldDirty: true,
          })
        }
      }}
    />
  </>
)

const RankingRewardElements = ({ register, errors }): JSX.Element => (
  <>
    <Row>
      <Col md={12}>
        <FormLabel label='ランキング対象者' />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ color: '#a9a9a9' }}>ランキング</span>
          <FormControl
            type='number'
            bsSize='small'
            name='coupon.start_rank'
            bsClass='form-control'
            inputRef={register({
              required: 'ランキング対象者を入力してください',
              valueAsNumber: true,
              min: {
                value: 1,
                message: '1以上の数字を入力してください。',
              },
            })}
            style={{ width: 100 }}
          />
          <span style={{ color: '#a9a9a9' }}>位 〜 </span>
          <FormControl
            type='number'
            bsSize='small'
            name='coupon.end_rank'
            bsClass='form-control'
            inputRef={register({
              required: 'ランキング対象者を入力してください',
              valueAsNumber: true,
              min: {
                value: 1,
                message: '1以上の数字を入力してください。',
              },
            })}
            style={{ width: 100 }}
          />
          <span style={{ color: '#a9a9a9' }}>位まで</span>
        </div>
        <HelpBlock className='text-danger'>
          {errors?.coupon?.expiration_day?.message ||
            errors?.coupon?.expiration_time?.message}
        </HelpBlock>
      </Col>
    </Row>
  </>
)

const generateDefaultValues = (
  fetchedCoupon,
  startRank,
  endRank,
  category,
  questionnaireId,
  rankId,
  customer_unit_price,
) => ({
  coupon: fetchedCoupon
    ? {
        ...fetchedCoupon,
        image: fetchedCoupon.image_url,
        start_rank: startRank,
        end_rank: endRank,
      }
    : {
        category: category,
        status: CouponStatus.Unpublished,
        benefit: Boolean(questionnaireId)
          ? 'アンケートに回答してクーポンを獲得！'
          : '',
        body: '',
        terms:
          '他のクーポンとの併用はできません。\nクーポンのみでのお店のご利用はお控えください。',
        important_notes:
          '・クーポン利用の際はスタッフにこの画面を提示して下さい\n・一度利用すると操作の取り消しができません',
        image: DEFAULTIMAGE,
        published_max_number: null,
        multiple_get_enabled: null,
        threshold_point: null,
        service_type: null,
        service_value: null,
        rank_id: rankId || null,
        questionnaire_id: questionnaireId || null,
        customer_unit_price: customer_unit_price || 0,
        memo: '',
        start_rank: null,
        end_rank: null,
        expiration_day: null,
        expiration_time: null,
      },
})

export default PrivilegeCouponFormView
