export const CouponUsageType = {
  default: 'ボタン押下',
  qrcode: 'QRコード',
  barcode: 'バーコード',
} as const

export const BarcodeFormats = ['CODE128', 'EAN13', 'codabar'] as const

export const BarcodeValidationRules: Record<
  typeof BarcodeFormats[number],
  {
    validate: (value: string) => true | string
    format: (value: string) => string
  }
> = {
  CODE128: {
    validate: (value) => (value ? true : 'バーコードの値を入力してください。'),
    format: (value) => value,
  },
  EAN13: {
    validate: (value) => {
      if (!/^\d{13}$/.test(value)) {
        return 'EAN13は13桁の数字のみ入力可能です。'
      }
      return true
    },
    format: (value) => {
      let formattedValue = value.replace(/\D/g, '').slice(0, 12)
      if (formattedValue.length === 12) {
        formattedValue += getEAN13CheckDigit(formattedValue)
      }
      return formattedValue
    },
  },
  codabar: {
    validate: (value) => {
      // 先頭/末尾のA-D文字の整合性チェック
      const hasStartChar = value && /^[A-Da-d]/.test(value);
      const hasEndChar = value && /[A-Da-d]$/.test(value);
      if (!(hasStartChar && hasEndChar)) {
        return 'NW-7（Codabar）形式では、先頭と末尾の両方にスタート/ストップ文字A,B,C,D（a,b,c,d）が必要です';
      }

      // データ部分（先頭/末尾のA-D文字を除く）の文字種チェック
      const dataContent = value.replace(/^[A-Da-d]|[A-Da-d]$/g, '')
      if (!/^[0-9\-$:\/\.\+]*$/.test(dataContent)) {
        return 'NW-7（Codabar）形式では、スタート/ストップ文字の間は数字と記号(-,$,:,/,.,+)のみ使用可能です';
      }

      return true;
    },
    format: (value) => value,
  },
}

// EAN13チェックディジットの計算の詳細は以下のURLを参照してください。
// https://www.gs1jp.org/code/jan/check_digit.html
export const getEAN13CheckDigit = (checkBarcode: string): string => {
  if (!/^\d{12}$/.test(checkBarcode)) return '' // 12桁の数字でない場合はエラー
  const digits = checkBarcode.split('').map(Number)

  const sum = digits.reduce(
    (acc, num, index) => acc + (index % 2 === 0 ? num : num * 3),
    0,
  )
  const checkDigit = (10 - (sum % 10)) % 10

  return checkDigit.toString()
}
