import { useState, useCallback } from 'react'
import { createContainer } from 'unstated-next'
import { stampCardApi } from '../../lib/api'
import { PaymentBenefit } from '../../types/stampCard.d'

export type StampCardContent = {
  active: boolean
  status: StampCardStatus
  check_in_enabled: boolean
  connection_stamp: number
  created_at: string
  how_to_get_stamp: string
  how_to_uses: string
  id: number
  max_stamp: number
  payment_benefit: PaymentBenefit
  special_check_in_enabled: boolean
  special_check_in_stamp: number | null
  stamp_image_url: string | null
  common_stamp_image_url: string | null
  stamp_image: string | null
  stamp_qrcodes: StampQrcode[]
  stamp_value: number | null
  store_id: number
  restrict_one_stamp_per_store: boolean
  custom_settings_enabled: boolean
  use_common_stamp_image: boolean
  hide_stamp_name: boolean
}

type StampQrcode = {
  category: 'stamp'
  created_at: string
  id: number
  image_url: string
  name: string
  stamp: number
}

export const stampCardStatus = {
  Draft: 'draft',
  Preview: 'preview',
  Open: 'open',
  Close: 'close',
} as const

export type StampCardStatus =
  typeof stampCardStatus[keyof typeof stampCardStatus]

const stampCardStatuses: StampCardStatus[] = [
  'draft',
  'open',
  'close',
  'preview',
]

export const stampCardStatusesToJpObj = {
  [stampCardStatus.Draft]: '下書き',
  [stampCardStatus.Open]: '公開中',
  [stampCardStatus.Close]: '非公開',
  [stampCardStatus.Preview]: '下書き',
}

const useStampCardContentEntityHook = () => {
  const [stampCardContent, setStampCardContent] = useState<StampCardContent>()

  return {
    state: { stampCardContent },
    constant: { stampCardStatuses },
    logic: {
      getStampCardContent: useCallback(async () => {
        const res = await stampCardApi.getStampCardContent()
        setStampCardContent(res?.data)
      }, []),
    },
  }
}

export default createContainer(useStampCardContentEntityHook)
