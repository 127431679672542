import React, { useState } from 'react'
import { styled } from '@mui/system'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Popover from '@mui/material/Popover'
import { useLoginContext } from '../../../providers/LoginContextProvider'
import StoreSelectModal from './StoreSelectModal'

const SidebarHeader = (): JSX.Element => {
  const { currentStaff, currentStore } = useLoginContext()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const storeCount =
    currentStaff.groups.find((g) => g.id === currentStore.id)?.stores_count || 1

  // ここでは店舗切り替えのモーダルを表示するべきかどうかをチェックしており
  // ログイン中のスタッフが所属する店舗またはグループの数が合わせて
  // 2個以上の場合は店舗切り替えできるようにしています。
  // なお、店舗が1つしかなくて、スタッフがその店舗とオーナーにしか所属していない場合
  // (個人でやっている小さな店舗などがこういうケースが多い)
  // スタッフにオーナーという概念を意識させる必要がないため
  // 店舗切り替えのモーダルは表示しないようにします。
  // ただし、切り替えができないとオーナーグループの店舗で保存されているログの削除ができなかったりと何かと不便なので
  // トイポスタッフに関しては、切り替えができるようになっています。
  const groupStores = currentStaff.groups.filter(
    (group) => group.store_type === 'store_group',
  )
  const canSelectStore =
    currentStaff.stores.length + groupStores.length > 1 ||
    currentStaff.is_toypo_member

  const handleOpenViewObjModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (canSelectStore) {
      setAnchorEl(e.currentTarget)
    }
  }

  return (
    <>
      <StyledStoreNameButton
        startIcon={
          <StyledIconContainer>
            {currentStore.app_icon_url ? (
              <img src={currentStore.app_icon_url} alt='店舗画像' />
            ) : (
              <i
                className={DefaultIconMap[currentStore.store_type]}
                style={{ fontSize: 22 }}
              />
            )}
          </StyledIconContainer>
        }
        endIcon={
          canSelectStore && (
            <i className='ri-expand-up-down-line' style={{ fontSize: 16 }} />
          )
        }
        onClick={handleOpenViewObjModal}
      >
        <div>
          {currentStore.name}
          {storeCount > 1 && (
            <Box fontSize={12} fontWeight={400} mt={0.5}>
              {storeCount}店舗
            </Box>
          )}
          {currentStore.status === 'close' && <StyledBadge>非公開</StyledBadge>}
        </div>
      </StyledStoreNameButton>

      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <StoreSelectModal onClose={() => setAnchorEl(null)} />
      </Popover>
    </>
  )
}

const DefaultIconMap = {
  child: 'ri-building-fill',
  store_group: 'ri-building-fill',
  owner_group: 'ri-building-4-fill',
} as const

const StyledStoreNameButton = styled(Button)(({ theme }) => ({
  color: '#fff',
  fontSize: 14,
  lineHeight: '1.2',
  fontWeight: 700,
  textAlign: 'left',
  justifyContent: 'flex-start',
  textTransform: 'none',
  padding: theme.spacing(2),
  '& .MuiButton-endIcon': {
    flex: 1,
    textAlign: 'right',
    display: 'block',
  },
}))

const ICON_SIZE = 40
const StyledIconContainer = styled('div')({
  backgroundColor: '#fff',
  color: '#ADC1DF',
  width: ICON_SIZE,
  height: ICON_SIZE,
  borderRadius: ICON_SIZE / 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& > img': {
    width: ICON_SIZE,
    height: ICON_SIZE,
    borderRadius: ICON_SIZE / 2,
  },
})

const StyledBadge = styled('div')({
  color: '#4b4b4b',
  backgroundColor: 'white',
  padding: '4px 8px',
  borderRadius: 6,
  fontWeight: 'bold',
  fontSize: 12,
  width: 60,
  textAlign: 'center',
})

export default SidebarHeader
