import React, { useState, useEffect, useCallback } from 'react'
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Select,
  CircularProgress,
  Pagination,
} from '@mui/material'
import { useLocation, useHistory } from 'react-router'
import CancelIcon from '@mui/icons-material/Cancel'
import { useApi, useQuery } from '../../lib/hooks'

const StoreSpotsView = () => {
  const { storeStores, totalPages, currentPage, changeUrl, reload } =
    useStoreSpotsApi()

  if (!storeStores) return <CircularProgress />

  return (
    <Box sx={{ m: 4 }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ bgcolor: '#f5f5f5' }}>
            <TableRow>
              <TableCell>Store ID</TableCell>
              <TableCell>店舗名</TableCell>
              <TableCell>住所</TableCell>
              <TableCell>スポット登録</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {storeStores.map(({ store, spot }) => (
              <TableRow
                key={store.id}
                style={{
                  backgroundColor: spot?.status === 'active' ? 'gray' : '#fff',
                }}
              >
                <TableCell>{store.id}</TableCell>
                <TableCell>{store.name}</TableCell>
                <TableCell>{store.address}</TableCell>
                <TableCell>
                  <StoreSpotFormItem
                    spot={spot}
                    store={store}
                    reload={reload}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(_, page) => changeUrl({ page })}
        />
      </TableContainer>
    </Box>
  )
}

const StoreSpotFormItem = ({ spot, store, reload }) => {
  const { api } = useApi('staff')
  const {
    api: googlePlaceApi,
    response: googlePlacesResponse,
    reset,
  } = useApi('staff')
  const searchGooglePlaces = async (store_id: number) => {
    await googlePlaceApi.post('/spots/search_google_places', { store_id })
  }

  const [selectedGooglePlaceId, setSelectedGooglePlaceId] = useState<string>('')

  return (
    <>
      {!googlePlacesResponse ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {spot && (
            <div>
              {spot.google_place_id || '登録されているスポットがありません。'}
            </div>
          )}
          <Button onClick={() => searchGooglePlaces(store.id)}>
            スポット取得
          </Button>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Select
            value={selectedGooglePlaceId}
            onChange={(e) => setSelectedGooglePlaceId(e.target.value)}
            displayEmpty
          >
            {googlePlacesResponse.length === 0 ? (
              <MenuItem value=''>該当のスポットがありません。</MenuItem>
            ) : (
              googlePlacesResponse.map((googlePlace) => (
                <MenuItem key={googlePlace.id} value={googlePlace.id}>
                  {googlePlace.displayName.text}
                </MenuItem>
              ))
            )}
          </Select>

          <Button
            variant='contained'
            onClick={async () => {
              const params = {
                store_id: store.id,
                google_place_id: selectedGooglePlaceId,
              }
              if (spot) {
                await api.patch(`/spots/${spot.id}`, params)
              } else {
                await api.post(`/spots`, params)
              }
              reload()
              setSelectedGooglePlaceId('')
              reset()
            }}
          >
            Spot登録
          </Button>

          <Button
            startIcon={<CancelIcon />}
            color='error'
            onClick={() => {
              setSelectedGooglePlaceId('')
              reset()
            }}
          >
            キャンセル
          </Button>
        </Box>
      )}
    </>
  )
}

type QueryParams = {
  page: number
}

const useStoreSpotsApi = () => {
  const history = useHistory()
  const location = useLocation()
  const query = useQuery()

  const { api: adminApi, response, totalPages, currentPage } = useApi('admin')
  const [queryParams, setQueryParams] = useState<QueryParams>({
    page: Number(query.get('page') || 1),
  })

  const changeUrl = useCallback(
    (params: QueryParams) => {
      setQueryParams(params)

      const searchParams = new URLSearchParams()
      searchParams.append('page', String(params.page))
      const newUrl = `${location.pathname}?${searchParams.toString()}`
      history.replace(newUrl)
    },
    [history, location.pathname],
  )

  const getStores = useCallback(() => {
    adminApi.get('/stores', { page: queryParams.page })
  }, [adminApi, queryParams])

  useEffect(() => {
    getStores()
  }, [getStores])

  return {
    changeUrl,
    reload: getStores,
    storeStores: response?.stores || [],
    totalPages,
    currentPage,
  }
}

export default StoreSpotsView
