import React, { useCallback, useEffect } from 'react'
import { Row, Col, FormGroup, Form, Table } from 'react-bootstrap'
import Toggle from 'react-toggle'
import { Controller, useForm } from 'react-hook-form'
import { useNotification } from '../../../providers/NotificationProvider'
import { useHistory } from 'react-router-dom'
import { FormLabel, Pagination } from '@mui/material'
import SmallTableBody from '../../../components/Table/SmallTableBody'
import { Card } from '../../../components/Card/Card'
import LoadingButton from '../../../components/CustomButton/LoadingButton'
import { StampCardContent } from '../../../containers/entities/StampCardContentEntityContainer'
import { usePcSizeFlag } from '../../../lib/hooks'
import { useApi, useBooleanState } from '../../../lib/hooks'
import Checkbox from '../../../components/CustomCheckbox/CustomCheckbox'
import { StampCardsContentCustomSettingsResponse } from '../../../types/api/stampCard'
import { AxiosError } from 'axios'
import {
  FormChangedAlertModal,
  ModalContentFormChangedList,
} from '../../../components/Modal/FormChangedAlertModal'

type Props = {
  stampCardContent: StampCardContent
  onUpdatedStampCardCustomSetting: () => void
}

const FieldLabels = {
  custom_settings_enabled: 'スタンプ画像・スタンプ名の個別設定',
  hide_stamp_name: 'スタンプ名を非表示にする',
} as const

const StoreSettingView = ({
  stampCardContent,
  onUpdatedStampCardCustomSetting,
}: Props): JSX.Element => {
  const history = useHistory()
  const isPcOrTablet = usePcSizeFlag()

  const defaultValues = {
    custom_settings_enabled: stampCardContent.custom_settings_enabled,
    hide_stamp_name: stampCardContent.hide_stamp_name,
    use_common_stamp_image: stampCardContent.use_common_stamp_image,
  }

  const [
    isStatusChangeModalOpen,
    openStatusChangeModal,
    closeStatusChangeModal,
  ] = useBooleanState(false)

  type StampCardContentCustomSettingForm = {
    custom_settings_enabled: boolean
    hide_stamp_name: boolean
  }

  const methods = useForm<StampCardContentCustomSettingForm>({ defaultValues })

  const { control, handleSubmit, getValues, reset, watch } = methods

  const { isDirty, dirtyFields } = methods.formState

  const watchCustomSettingEnabled = watch(
    'custom_settings_enabled',
    defaultValues.custom_settings_enabled,
  )
  const watchHideStampName = watch(
    'hide_stamp_name',
    defaultValues.hide_stamp_name,
  )
  const watchUseCommonStampImage = !watch(
    'use_common_stamp_image',
    defaultValues.use_common_stamp_image,
  )

  const { api, loading } = useApi()
  const PAGE_LIMIT = isPcOrTablet ? 20 : 4
  const { showSuccessNotification, showErrorNotification } = useNotification()
  const {
    api: stampCardsContentCustomSettingsGetApi,
    response: stampCardsContentCustomSettingsResponse,
    totalPages,
    currentPage,
  } = useApi<Array<StampCardsContentCustomSettingsResponse>>()
  const stampCardsContentCustomSettings: Array<StampCardsContentCustomSettingsResponse> =
    stampCardsContentCustomSettingsResponse || []

  const getStampCardContentCustomSettings = useCallback(
    (page = 1) => {
      stampCardsContentCustomSettingsGetApi.get(
        '/stamp_card_content_custom_settings',
        {
          page: page,
          sort: 'created_at',
          order: 'desc',
          limit: PAGE_LIMIT,
        },
      )
    },
    [stampCardsContentCustomSettingsGetApi, PAGE_LIMIT],
  )

  useEffect(() => {
    if (watchCustomSettingEnabled) {
      getStampCardContentCustomSettings()
    }
  }, [
    watchCustomSettingEnabled,
    getStampCardContentCustomSettings,
    stampCardContent,
  ])

  const onError = () => {
    showErrorNotification('値が正しく入力されていません。')
  }

  const updateStampCardCustomSetting = async () => {
    const values = getValues()
    try {
      const res = await api.patch('/stamp_card_contents', {
        stamp_card_content: values,
      })
      if (res) {
        reset(values)
        showSuccessNotification(`スタンプカードを更新しました。`)
        onUpdatedStampCardCustomSetting()
      }
    } catch (error) {
      showErrorNotification((error as AxiosError).message)
    }
    closeStatusChangeModal()
  }

  return (
    <>
      <Row>
        <Col md={12}>
          <Card
            title='店舗別設定'
            content={
              <Form onSubmit={handleSubmit(openStatusChangeModal, onError)}>
                <FormGroup bsSize='large'>
                  <Row>
                    <Col md={4} sm={5} xs={12}>
                      <FormLabel>スタンプ画像・スタンプ名の個別設定</FormLabel>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4} sm={5} xs={12}>
                      <Controller
                        control={control}
                        name='custom_settings_enabled'
                        render={({ onChange, value }) => (
                          <Toggle
                            checked={value}
                            onChange={(event) => {
                              onChange(event.target.checked)
                            }}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  {watchCustomSettingEnabled &&
                    stampCardsContentCustomSettings.length > 0 && (
                      <>
                        <Table striped hover>
                          {isPcOrTablet && (
                            <thead>
                              <tr>
                                <th> </th>
                                <th>表示店舗名（10文字以内推奨）</th>
                                {watchHideStampName && <th>スタンプ画像</th>}
                              </tr>
                            </thead>
                          )}
                          <tbody>
                            {stampCardsContentCustomSettings.map((item) => (
                              <tr
                                key={item.id}
                                onClick={(): void => {
                                  history.push('custom_stamp/edit', {
                                    customStampCardSetting: item,
                                  })
                                }}
                                style={{ cursor: 'pointer' }}
                              >
                                {isPcOrTablet ? (
                                  <>
                                    <td>
                                      <div>
                                        <b>{item.current_store_name}</b>
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        <b>
                                          {watchHideStampName
                                            ? '非表示'
                                            : item.name}
                                        </b>
                                      </div>
                                    </td>

                                    {watchUseCommonStampImage && (
                                      <img src={item.image_url} height={50} />
                                    )}
                                  </>
                                ) : (
                                  <SmallTableBody
                                    image={item.image_url}
                                    title={item.current_store_name}
                                    contents={[
                                      `表示店舗名（10文字以内推奨）:  ${
                                        watchHideStampName
                                          ? '非表示'
                                          : item.name
                                      }`,
                                    ]}
                                  />
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <Controller
                          control={control}
                          name='hide_stamp_name'
                          render={({ onChange, value }) => (
                            <Checkbox
                              inline
                              label='スタンプ名を非表示にする'
                              checked={value}
                              onChange={(e) => {
                                onChange(e.target.checked)
                              }}
                            />
                          )}
                        />
                      </>
                    )}
                </FormGroup>
                <LoadingButton
                  type='submit'
                  label='更新する'
                  loadingLabel='更新中...'
                  color='info'
                  fill
                  pullRight
                  disabled={!isDirty}
                  loading={loading}
                />
                <div className='clearfix' />
              </Form>
            }
            stats={
              totalPages > 1 ? (
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={(_, page) =>
                    getStampCardContentCustomSettings(page)
                  }
                  sx={{ fontSize: 24, color: 'black' }}
                />
              ) : null
            }
          />
        </Col>
      </Row>

      <FormChangedAlertModal
        show={isStatusChangeModalOpen}
        title='店舗別設定を更新'
        onSubmit={updateStampCardCustomSetting}
        onCancel={closeStatusChangeModal}
        disabled={loading}
      >
        店舗別設定の内容を更新してよろしいですか？
        <ModalContentFormChangedList
          changedProperties={Object.entries(FieldLabels)
            .map(([key, label]) => (dirtyFields[key] ? label : ''))
            .filter((v) => v)}
        />
      </FormChangedAlertModal>
    </>
  )
}

export default StoreSettingView
