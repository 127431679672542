import React, { useCallback, useEffect, useState } from 'react'
import { Grid, Alert } from 'react-bootstrap'
import StampCardContentEntityContainer, {
  StampCardStatus,
  stampCardStatusesToJpObj,
} from '../../containers/entities/StampCardContentEntityContainer'
import { CouponContentsResponse } from '../../types/api/coupon.d'
import { useApi, usePcSizeFlag } from '../../lib/hooks'
import { useNotification } from '../../providers/NotificationProvider'
import StampCardFormView from './parts/StampCardFormView'
import PrivilegeStampListView from './parts/PrivilegeStampListView'
import StampCardCustomSettingView from './parts/StampCardCustomSettingView'
import StatusBar from '../../components/StatusBar'
import StampCardPublishSettingCard from './parts/StampCardPublishSettingCard'
import { useLoginContext } from '../../providers/LoginContextProvider'

const StampCardView = (): JSX.Element => {
  const { showSuccessNotification } = useNotification()
  const stampCardContentEntityContainer =
    StampCardContentEntityContainer.useContainer()
  const { stampCardContent } = stampCardContentEntityContainer.state
  const { getStampCardContent } = stampCardContentEntityContainer.logic
  const { api: stampCardStatusUpdateApi, loading: stampCardStatusLoading } =
    useApi()
  const {
    api: privilegeContentsGetApi,
    response: privilegeContentsResponse,
    totalPages,
    currentPage,
  } = useApi<CouponContentsResponse>()

  const isPcOrTablet = usePcSizeFlag()
  const PAGE_LIMIT = isPcOrTablet ? 20 : 4
  const privilegeCouponContents =
    privilegeContentsResponse?.coupon_contents || []
  const publishCouponContents = privilegeCouponContents.filter(
    ({ coupon_content }) => coupon_content.status === 'publish',
  )
  const [useCommonStampImage, setUseCommonStampImage] = useState<
    undefined | boolean
  >(undefined)

  const { currentStore } = useLoginContext()
  const isOwnerGroup = currentStore.store_type === 'owner_group'
  const isStoreGroup = currentStore.store_type === 'store_group'
  const isGroup = isOwnerGroup || isStoreGroup

  const getPrivilegeContents = useCallback(
    (page = 1) => {
      privilegeContentsGetApi.get('/coupon_contents', {
        type: 'stamp',
        page: page,
        sort: 'created_at',
        order: 'desc',
        limit: PAGE_LIMIT,
      })
    },
    [privilegeContentsGetApi, PAGE_LIMIT],
  )

  useEffect(() => {
    getStampCardContent()
    getPrivilegeContents()
  }, [getStampCardContent, getPrivilegeContents])

  const handleChangeStatus = async (status: StampCardStatus) => {
    const params = { stamp_card_content: { status } }

    const res = await stampCardStatusUpdateApi.patch(
      '/stamp_card_contents',
      params,
    )
    if (res) {
      showSuccessNotification('更新しました。')
      getStampCardContent()
    }
  }

  return (
    <div>
      {stampCardContent && (
        <StatusBar
          style={{ marginBottom: 30 }}
          published={stampCardContent.status === 'open'}
          label={`スタンプカードは${
            stampCardStatusesToJpObj[stampCardContent.status]
          }です`}
        />
      )}

      <div>
        <Grid fluid>
          {stampCardContent?.status === 'open' &&
            publishCouponContents.length === 0 && (
              <Alert bsStyle='warning'>
                スタンプカードは公開中ですが、特典がまだありません。
              </Alert>
            )}

          {stampCardContent && (
            <StampCardFormView
              stampCardContent={stampCardContent}
              disabled={stampCardStatusLoading}
              onUpdatedStampCardContent={getStampCardContent}
              onAddedStampCardQrCode={getStampCardContent}
              onDeletedStampCardQrCode={getStampCardContent}
              onChangeUseCommonStampImage={(checked: boolean) => {
                setUseCommonStampImage(checked)
              }}
            />
          )}

          {stampCardContent && isGroup && (
            <StampCardCustomSettingView
              stampCardContent={{
                ...stampCardContent,
                use_common_stamp_image:
                  useCommonStampImage === undefined
                    ? stampCardContent.use_common_stamp_image
                    : useCommonStampImage,
              }}
              onUpdatedStampCardCustomSetting={getStampCardContent}
            />
          )}

          <PrivilegeStampListView
            stampCardContent={stampCardContent}
            privilegeCouponContents={privilegeCouponContents}
            onChangePage={getPrivilegeContents}
            totalPages={totalPages}
            currentPage={currentPage}
          />

          {stampCardContent && (
            <StampCardPublishSettingCard
              status={stampCardContent.status}
              disabled={stampCardStatusLoading}
              onChangeStatus={handleChangeStatus}
            />
          )}
        </Grid>
      </div>
    </div>
  )
}

export default StampCardView
