import React, { useState, useEffect } from 'react'
import { FormProvider, useForm, useWatch, Controller } from 'react-hook-form'
import { withRouter, Prompt } from 'react-router'
import { useHistory, useLocation } from 'react-router-dom'
import { Grid, Row, Col, Form, HelpBlock } from 'react-bootstrap'
import {
  Backdrop,
  CircularProgress,
  Select,
  MenuItem,
  Box,
} from '@mui/material'
import MembersCardContentEntityContainer, {
  MembersCardRankInterface,
  MembersCardRankStatus,
  MembersCardContentInterface,
  ColorType,
} from '../../containers/entities/MembersCardContentEntityContainer'
import FormInputs from '../../components/FormInputs/FormInputs'
import SelectorForm from '../../components/FormInputs/SelectorForm'
import FormGroup from '../../components/FormInputs/FormGroup'
import FormLabel from '../../components/FormInputs/FormLabel'
import LoadingButton from '../../components/CustomButton/LoadingButton'
import { Card } from '../../components/Card/Card'
import Button from '../../components/CustomButton/CustomButton'
import ImageDisplay from '../../components/Image/ImageDisplay'
import ServiceListTable from './parts/ServiceListTable'
import RankupCouponListTable from './parts/RankupCouponListTable'
import MembersCardRankPublishSettingCard from './parts/MembersCardRankPublishSettingCard'
import { formatDateExceptTime, getObjectDiff } from '../../lib/general'
import { useGetApiCall } from '../../lib/hooks'
import {
  createMembersCardRankQrcode,
  deleteMembersCardRankQrcode,
} from '../../lib/api/qrcodes'
import { useNotification } from '../../providers/NotificationProvider'
import {
  MemberCardRankColors,
  MemberCardRankColorLabels,
  MemberCardRankImages,
  RankUpMethodLabel,
} from '../../constants/membersCard'
import { ConditionalRender } from '../../components/ConditionalRender'

const MembersCardRankView = () => {
  const history = useHistory()
  const location = useLocation<{ rankId: number }>()
  const { showSuccessNotification, showErrorNotification } = useNotification()
  const [membersCardRank, setMembersCardRank] =
    useState<MembersCardRankInterface | null>(null)
  const [isPointSetting, setIsPointSetting] = useState(false)
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [isBackdropLoading, setIsBackdropLoading] = useState(false)
  const membersCardContentApi = useGetApiCall<MembersCardContentInterface>(
    '/members_card_contents',
  )
  const membersCardContent = membersCardContentApi?.response

  const checkInPoint = typeof membersCardRank?.check_in_point === 'number'
  const paymentPointAmount =
    typeof membersCardRank?.payment_point_amount === 'number'
  const pointRate = typeof membersCardRank?.point_rate === 'number'

  useEffect(() => {
    setIsPointSetting(checkInPoint || paymentPointAmount || pointRate)
  }, [checkInPoint, paymentPointAmount, pointRate])

  const membersCardEntityContainer =
    MembersCardContentEntityContainer.useContainer()
  const {
    getMembersCardRank,
    updateMembersCardRank,
    createMembersCardRank,
    membersCardRankStatusToJp,
  } = membersCardEntityContainer.logic

  const rankId = location.state?.rankId
  const editMode = !!rankId

  const methods = useForm<{
    membersCardRank: MembersCardRankInterface
  }>({
    defaultValues: {
      membersCardRank: {
        status: 'close',
        name: '',
        description: '',
        display_name: '',
        need_value: 0,
        point_enabled: true,
        check_in_point: null,
        payment_point_amount: null,
        point_rate: null,
        color: 'blue',
        is_default_rank: false,
        expiration_month: null,
        rank_up_method: 'rank_up_by_qrcode',
      },
    },
  })

  const {
    formState,
    register,
    handleSubmit,
    getValues,
    watch,
    errors,
    reset,
    trigger,
    control,
    setValue,
  } = methods

  const { isDirty, dirtyFields } = formState

  const watchColor = useWatch<ColorType>({
    control,
    name: 'membersCardRank.color',
    defaultValue: 'blue',
  })
  const watchRankUpMethod = watch('membersCardRank.rank_up_method')
  const watchStatus = watch('membersCardRank.status') as MembersCardRankStatus

  useEffect(() => {
    // 編集画面時に発火
    if (rankId) {
      getMembersCardRank(rankId).then((rank) => {
        const resetValues = {
          ...rank,
          point_rate: rank.point_rate ? rank.point_rate * 100 : rank.point_rate,
        }
        reset({ membersCardRank: resetValues })
        setMembersCardRank(rank)
      })
    }
  }, [getMembersCardRank, rankId, reset, setValue])

  useEffect(() => {
    // 作成画面時に発火
    if (!rankId && membersCardContent?.default_rank_up_method) {
      reset({
        membersCardRank: {
          rank_up_method: membersCardContent.default_rank_up_method,
        },
      })
    }
  }, [membersCardContent, rankId, reset])

  const processFormData = (data): MembersCardRankInterface => {
    const proccedData = data

    // ポイント付与率を変更しない場合、0ではなくnullを送信する
    if (!isPointSetting) {
      proccedData.check_in_point = null
      proccedData.payment_point_amount = null
      proccedData.point_rate = null
    }

    if (proccedData.point_rate) {
      proccedData.point_rate /= 100
    }

    if (editMode) {
      delete proccedData.status
    }

    // ランクアップ方法を明示的に設定
    proccedData.rank_up_method = watchRankUpMethod

    return proccedData
  }

  const onClickUpdateButton = async (data): Promise<void> => {
    const formData = processFormData(data.membersCardRank)
    const params = getObjectDiff(membersCardRank, formData)

    await updateMembersCardRank(rankId, params)
      .then((newMembersCardRank) => {
        showSuccessNotification('更新しました。')
        if (newMembersCardRank) {
          reset({ membersCardRank: newMembersCardRank })
        }
        history.goBack()
      })
      .catch((error) => {
        showErrorNotification(error.message)
      })
  }

  const onClickCreateButton = async (data): Promise<void> => {
    const formData = processFormData(data.membersCardRank)

    await createMembersCardRank(formData)
      .then((newMembersCardRank) => {
        showSuccessNotification('更新しました。')
        if (newMembersCardRank) {
          reset({ membersCardRank: newMembersCardRank })
        }
        history.goBack()
      })
      .catch((error) => {
        showErrorNotification(error.message)
      })
  }

  const onClickCreateMembersCardRankQrcode = async (): Promise<void> => {
    if (!window.confirm('会員ランク更新用QRコードを追加しますか')) {
      return
    }
    try {
      await createMembersCardRankQrcode(rankId)
      getMembersCardRank(rankId).then((mcr) => {
        setMembersCardRank(mcr)
      })
      showSuccessNotification('追加しました。')
    } catch (error) {
      showErrorNotification(error.message)
    }
  }

  const onClickDeleteMembersCardRankQrcode = async (
    e,
    qrcodeId,
  ): Promise<void> => {
    e.preventDefault()
    if (!window.confirm('会員ランク更新用QRコードを削除しますか')) {
      return
    }
    try {
      await deleteMembersCardRankQrcode({
        members_card_rank_id: rankId,
        members_card_rank_qrcode_id: qrcodeId,
      })
      getMembersCardRank(rankId).then((mcr) => {
        setMembersCardRank(mcr)
      })
      showSuccessNotification('削除しました。')
    } catch (error) {
      showErrorNotification('削除できませんでした。')
    }
  }

  const onSubmit = (data): void => {
    setIsButtonLoading(true)
    if (editMode) {
      onClickUpdateButton(data).finally(() => setIsButtonLoading(false))
    } else {
      onClickCreateButton(data).finally(() => setIsButtonLoading(false))
    }
  }

  const onError = (): void => {
    showErrorNotification('値が正しく入力されていません。')
  }

  const onClickStatusUpdateButton = async (
    isChecked: boolean,
  ): Promise<void> => {
    const isDirtyExceptStatus = !!Object.keys(
      dirtyFields.membersCardRank || {},
    ).filter((key) => key !== 'status').length

    const isSave =
      isDirtyExceptStatus &&
      window.confirm(
        '行った変更が保存されていない可能性があります。変更内容を保存しますか？',
      )
    if (isSave && !(await trigger())) {
      showErrorNotification('値が正しく入力されていません。')
      return
    }

    const status = isChecked ? 'open' : 'close'
    const isUpdateStatus = window.confirm(
      `${status === 'open' ? '公開' : '非公開'}にしますか？`,
    )

    if (!isSave && !isUpdateStatus) {
      return
    }

    const data = getValues()
    const params = isSave
      ? {
          ...getObjectDiff(
            membersCardRank,
            processFormData(data.membersCardRank),
          ),
          status: isUpdateStatus ? status : undefined,
        }
      : { status: isUpdateStatus ? status : undefined }

    setIsBackdropLoading((prevIsBackdropLoading) => !prevIsBackdropLoading)
    // ステータスの更新
    await updateMembersCardRank(rankId, params)
      .then(() => {
        getMembersCardRank(rankId).then((mcr) => {
          reset({ membersCardRank: mcr })
          setMembersCardRank(mcr)
        })
        showSuccessNotification('更新しました。')
      })
      .catch((error) => {
        showErrorNotification(error.message)
      })
      .finally(() => {
        setIsBackdropLoading(false)
      })
  }

  let needValueObj = {
    label: '',
    unit: '',
  }
  switch (watchRankUpMethod) {
    case 'rank_up_by_check_in':
      needValueObj = {
        label: 'チェックイン数',
        unit: '回',
      }
      break
    case 'rank_up_by_payment':
      needValueObj = {
        label: 'お会計金額',
        unit: '円',
      }
      break
    case 'rank_up_by_point':
      needValueObj = {
        label: '獲得ポイント',
        unit: 'pt',
      }
      break
    default:
      break
  }

  const rankUpMethodOptions = membersCardContent
    ? [membersCardContent?.default_rank_up_method, 'rank_up_by_qrcode']
    : ['rank_up_by_qrcode']

  return (
    <FormProvider {...methods}>
      <Grid fluid className='content'>
        <Form onSubmit={handleSubmit(onSubmit, onError)}>
          {editMode && (
            <Row>
              <Col xs={12} lg={10}>
                <MembersCardRankPublishSettingCard
                  editMode={editMode}
                  watchStatus={watchStatus}
                  membersCardRank={membersCardRank}
                  membersCardRankStatusToJp={membersCardRankStatusToJp}
                  onClickStatusUpdateButton={onClickStatusUpdateButton}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={12} lg={10}>
              <Card
                title='会員ランク'
                content={
                  <>
                    <FormInputs
                      properties={[
                        {
                          name: 'membersCardRank.display_name',
                          label: 'カード表示用ランク名',
                          type: 'text',
                          bsClass: 'form-control',
                          ncol: 'col-md-6',
                          inputRef: register({
                            required:
                              'カード表示用ランク名を入力してください。',
                          }),
                          validationMessage:
                            errors?.membersCardRank?.display_name?.message,
                        },
                        {
                          name: 'membersCardRank.name',
                          label: '管理用ランク名',
                          type: 'text',
                          bsClass: 'form-control',
                          ncol: 'col-md-6',
                          inputRef: register({
                            required: '管理用ランク名を入力してください。',
                          }),
                          validationMessage:
                            errors?.membersCardRank?.name?.message,
                        },
                        {
                          name: 'membersCardRank.description',
                          label: 'ランク説明',
                          ncol: 'col-md-12',
                          rows: '6',
                          attachment: '任意',
                          componentClass: 'textarea',
                          bsClass: 'form-control',
                          inputRef: register,
                          validationMessage:
                            errors?.membersCardRank?.description?.message,
                        },
                      ]}
                    />

                    <Row>
                      <FormGroup className='col-md-7'>
                        <SelectorForm
                          name='membersCardRank.color'
                          inputRef={register}
                          label='カードカラー'
                          options={MemberCardRankColors.map((color) => ({
                            value: color,
                            label: MemberCardRankColorLabels[color],
                          }))}
                        />
                        <div
                          style={{
                            width: 220,
                            height: 100,
                            backgroundColor: '#f5f5f5',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <img
                            alt='会員カードプレビュー画像'
                            src={MemberCardRankImages[watchColor]}
                            style={{
                              width: 200,
                              height: 80,
                              borderRadius: 6,
                              objectFit: 'contain',
                            }}
                          />
                        </div>
                      </FormGroup>
                    </Row>

                    {membersCardRank &&
                      !membersCardRank.is_default_rank &&
                      watchRankUpMethod !== 'rank_up_by_qrcode' && (
                        <FormInputs
                          properties={[
                            {
                              name: 'membersCardRank.need_value',
                              label: `ランクアップに必要な${needValueObj.label}`,
                              type: 'number',
                              bsClass: 'form-control',
                              ncol: 'col-md-6',
                              unit: needValueObj.unit,
                              inputRef: register({
                                min: {
                                  value: 1,
                                  message: '0以上の数字を入力してください',
                                },
                                required: '値を入力してください',
                                valueAsNumber: true,
                              }),
                              validationMessage:
                                errors?.membersCardRank?.need_value?.message,
                              width: 200,
                            },
                          ]}
                        />
                      )}

                    {membersCardRank?.point_enabled &&
                      (membersCardContent?.check_in_point_enabled ||
                        membersCardContent?.payment_point_type !==
                          'disable') && (
                        <SelectorForm
                          label='ポイント付与率'
                          tooltipText='この会員ランクでのポイント付与率に変更することができます。'
                          value={isPointSetting ? 'change' : 'default'}
                          onSelect={({ target }): void => {
                            setIsPointSetting(target.value === 'change')
                          }}
                          options={[
                            {
                              value: 'default',
                              label: 'ポイント付与率を変更しない',
                            },
                            {
                              value: 'change',
                              label: 'ポイント付与率を変更する',
                            },
                          ]}
                        />
                      )}

                    {membersCardRank?.point_enabled && isPointSetting && (
                      <>
                        {membersCardContent?.check_in_point_enabled && (
                          <FormInputs
                            properties={[
                              {
                                label: 'チェックインポイント',
                                type: 'number',
                                bsClass: 'form-control',
                                ncol: 'col-md-6',
                                unit: 'ポイント',
                                name: 'membersCardRank.check_in_point',
                                inputRef: register({ valueAsNumber: true }),
                                width: 200,
                              },
                            ]}
                          />
                        )}
                        {membersCardContent?.payment_point_type !==
                          'disable' && (
                          <FormInputs
                            properties={[
                              !pointRate && paymentPointAmount
                                ? {
                                    label: 'お会計ポイント数',
                                    unit: '円で1ポイント',
                                    name: 'membersCardRank.payment_point_amount',
                                    inputRef: register({ valueAsNumber: true }),
                                    type: 'number',
                                    bsClass: 'form-control',
                                    ncol: 'col-md-6',
                                    width: 200,
                                  }
                                : {
                                    label: 'ポイント還元率',
                                    unit: '％還元',
                                    name: 'membersCardRank.point_rate',
                                    inputRef: register({
                                      validate: (rate) => {
                                        if (rate === '')
                                          return '数字を入力して下さい。'
                                        return (
                                          0 <= Number(rate) ||
                                          '0 ％未満の値は設定できません。'
                                        )
                                      },
                                      valueAsNumber: true,
                                    }),
                                    step: 'any',
                                    type: 'number',
                                    bsClass: 'form-control',
                                    ncol: 'col-md-3',
                                    validationMessage:
                                      errors.membersCardRank?.point_rate
                                        ?.message,
                                  },
                            ]}
                          />
                        )}
                      </>
                    )}

                    {membersCardContent?.default_rank_up_method !==
                      'rank_up_by_qrcode' &&
                      !membersCardRank?.is_default_rank &&
                      editMode && (
                        <ConditionalRender>
                          <Box mb={2}>
                            <FormLabel
                              label='ランクアップ方法'
                              style={{ color: 'purple' }}
                            />
                            <Controller
                              name='membersCardRank.rank_up_method'
                              control={control}
                              render={({ onChange, value }) => (
                                <Select
                                  value={value}
                                  onChange={onChange}
                                  sx={{
                                    '&.MuiSelect-root': {
                                      '& fieldset': {
                                        borderColor: 'purple',
                                      },
                                      '&:hover fieldset': {
                                        borderColor: 'purple',
                                      },
                                      '&.Mui-focused fieldset': {
                                        borderColor: 'purple',
                                      },
                                    },
                                    '& .MuiSvgIcon-root': {
                                      color: 'purple',
                                    },
                                  }}
                                >
                                  {rankUpMethodOptions.map((rankUpMethod) => (
                                    <MenuItem
                                      key={rankUpMethod}
                                      value={rankUpMethod}
                                    >
                                      {RankUpMethodLabel[rankUpMethod]}
                                    </MenuItem>
                                  ))}
                                </Select>
                              )}
                            />
                          </Box>
                        </ConditionalRender>
                      )}

                    {watchRankUpMethod === 'rank_up_by_qrcode' && (
                      <SelectorForm
                        name='membersCardRank.expiration_month'
                        label='ランク有効期間'
                        tooltipText='ランクアップしてからの有効期限を設定することが可能です。（期間が過ぎると初期ランクに設定されます）'
                        inputRef={register({ valueAsNumber: true })}
                        options={[...Array(13)].map((_, index) => ({
                          label: index ? `${index} ヶ月` : '設定しない',
                          value: index || '',
                        }))}
                      />
                    )}

                    {/* (QRコードでのランク更新 もしくは 初期ランク) かつ 編集時 に会員ランク更新用QRコード表示 */}
                    {(watchRankUpMethod === 'rank_up_by_qrcode' ||
                      membersCardRank?.is_default_rank) &&
                      editMode && (
                        <Row>
                          <FormGroup className='col-md-12'>
                            <FormLabel label='会員ランク更新用QRコード' />
                            <HelpBlock
                              className='text-muted'
                              style={{ fontSize: 11, marginBottom: 7 }}
                            >
                              お客様がQRコードを読み込むと、会員ランクを更新することができます。
                            </HelpBlock>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                              }}
                            >
                              {membersCardRank?.rank_qrcodes.map((qrCode) => (
                                <ImageDisplay
                                  key={qrCode.id}
                                  label={`作成日：${formatDateExceptTime(
                                    qrCode.created_at,
                                  )}`}
                                  href={qrCode.image_url}
                                  altMessage={qrCode.name}
                                  image={qrCode.image_url}
                                  width={120}
                                  height={120}
                                  filename={`${qrCode.name}.png`}
                                  onClickDeleteButton={(e): Promise<void> =>
                                    onClickDeleteMembersCardRankQrcode(
                                      e,
                                      qrCode.id,
                                    )
                                  }
                                />
                              ))}
                              <ImageDisplay
                                width={120}
                                height={120}
                                onClickCreateButton={
                                  onClickCreateMembersCardRankQrcode
                                }
                              />
                            </div>
                          </FormGroup>
                        </Row>
                      )}

                    {/* 編集画面，かつ基本設定でポイントが有効でランク設定でポイントが無効のとき表示 */}
                    {editMode &&
                      membersCardContent?.point_enabled &&
                      !membersCardRank?.point_enabled && (
                        <div>
                          会員カードの基本設定でポイント機能が有効となっていますが、このランクではポイント機能が無効となっています。
                        </div>
                      )}
                  </>
                }
              />

              {!editMode && (
                <Row>
                  <Col xs={12}>
                    <MembersCardRankPublishSettingCard
                      editMode={editMode}
                      watchStatus={watchStatus}
                      membersCardRank={membersCardRank}
                      membersCardRankStatusToJp={membersCardRankStatusToJp}
                      onClickStatusUpdateButton={onClickStatusUpdateButton}
                    />
                  </Col>
                </Row>
              )}

              <Row style={{ marginBottom: 80 }}>
                <Col md={5} xs={6} mdOffset={1}>
                  <Button block fill onClick={history.goBack}>
                    <b>もどる</b>
                  </Button>
                </Col>
                <Col md={5} xs={6}>
                  <LoadingButton
                    type='submit'
                    label={editMode ? '更新する' : '作成する'}
                    loadingLabel={editMode ? '更新中...' : '作成中...'}
                    loading={isButtonLoading}
                    disabled={!isDirty}
                    color='info'
                    fill
                    block
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          {editMode && (
            <>
              <ServiceListTable rankId={rankId} />
              <RankupCouponListTable rankId={rankId} history={history} />
            </>
          )}
        </Form>

        <Backdrop
          sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isBackdropLoading}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
        <Prompt
          when={isDirty}
          message='行った変更が保存されていない可能性があります。このページを離れますか？'
        />
      </Grid>
    </FormProvider>
  )
}

export default withRouter(MembersCardRankView)
