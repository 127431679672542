import React, { useEffect, useState } from 'react'
import FormInput from '../../../components/FormInputs/FormInput'
import {
  Controller,
  FormProvider,
  useForm,
  SubmitHandler,
} from 'react-hook-form'
import ImageForm from '../../../components/FormInputs/ImageForm'
import { useHistory, useLocation } from 'react-router-dom'
import { Form, Grid, Row, Col } from 'react-bootstrap'
import CustomButton from '../../../components/CustomButton/CustomButton'
import LoadingButton from '../../../components/CustomButton/LoadingButton'
import { Card } from '../../../components/Card/Card'
import { useNotification } from '../../../providers/NotificationProvider'
import { useApi } from '../../../lib/hooks'
import { Prompt } from 'react-router'
import { stampCardApi } from '../../../lib/api'
import { AxiosError } from 'axios'

type StampCardCustomSettingForm = {
  stamp_card_content_id: number
  store_id: number
  name: string
  image: string
}

type LocationState = {
  customStampCardSetting: {
    id?: number | null
    store_id: number
    stamp_card_content_id: number
    name: string
    current_store_name: string
    image_url: string
  }
}

const StampCardCustomSettingFormView = (): JSX.Element => {
  const location = useLocation<LocationState>()
  const { showSuccessNotification, showErrorNotification } = useNotification()
  const customSetting = location.state?.customStampCardSetting
  const defaultValues = {
    stamp_card_content_id: customSetting?.stamp_card_content_id,
    store_id: customSetting?.store_id,
    name: customSetting?.name,
    image: customSetting?.image_url,
    current_store_name: customSetting?.current_store_name,
  }
  const methods = useForm<StampCardCustomSettingForm>({ defaultValues })
  const { register, handleSubmit, errors, control, setValue } = methods
  const { isDirty, dirtyFields } = methods.formState
  const { loading } = useApi()
  const formRef = React.useRef(null)
  const history = useHistory()
  const [isButtonLoading, setIsButtonLoading] = useState(false)

  const onError = () => {
    showErrorNotification('値が正しく入力されていません。')
  }

  const onSubmit: SubmitHandler<{
    stamp_card_content_custom_settings: {
      stamp_card_content_id: number
      store_id: number
      name: string
      image: string
    }
  }> = async (data: any) => {
    const submitData = { ...data }
    setIsButtonLoading(true)
    try {
      if (!dirtyFields.image) {
        delete submitData.image
      }
      await stampCardApi.updateStampCardContentCustomSetting({
        params: {
          ...submitData,
          store_id: defaultValues.store_id,
          stamp_card_content_id: defaultValues.stamp_card_content_id,
        },
      })

      showSuccessNotification(`スタンプカードを更新しました。`)
      methods.reset({
        ...submitData,
        store_id: defaultValues.store_id,
        stamp_card_content_id: defaultValues.stamp_card_content_id,
      })
      setIsButtonLoading(false)
      history.goBack()
    } catch (error) {
      setIsButtonLoading(false)
      showSuccessNotification((error as AxiosError).message)
    }
  }

  return (
    <FormProvider {...methods}>
      <div className='content'>
        <Grid fluid>
          <Form onSubmit={handleSubmit(onSubmit, onError)} ref={formRef}>
            <Row style={{ marginBottom: 10 }}>
              <Col md={10}>
                <Card
                  title={defaultValues.current_store_name}
                  content={
                    <>
                      <FormInput
                        type='text'
                        label='スタンプ名'
                        name='name'
                        note='10文字以内推奨。スタンプ画像の下に表示されます。'
                        placeholder='表示店舗名'
                        inputRef={register()}
                        required
                        disabled={loading}
                      />
                      <Controller
                        control={control}
                        name='image'
                        render={({ value }) => (
                          <ImageForm
                            doCompress
                            doTrimming
                            canDelete
                            label='スタンプ画像'
                            value={value}
                            width={175}
                            height={175}
                            aspect={1 / 1}
                            validationMessage={errors?.image?.message}
                            onChange={(img) => {
                              setValue('image', img, {
                                shouldDirty: true,
                              })
                            }}
                            deleteImage={() => {
                              setValue('image', null, {
                                shouldDirty: true,
                              })
                            }}
                            showChangeIndicator={Boolean(dirtyFields.image)}
                          />
                        )}
                      />
                      <Row
                        style={{
                          marginBottom: 30,
                          display: 'flex',
                          flexDirection: 'row',
                          gap: 12,
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Col>
                          <CustomButton block fill onClick={history.goBack}>
                            <b>もどる</b>
                          </CustomButton>
                        </Col>
                        <Col>
                          <LoadingButton
                            type='submit'
                            label={'更新する'}
                            loadingLabel={'編集中...'}
                            color='info'
                            block
                            fill
                            disabled={!isDirty}
                            loading={isButtonLoading}
                          />
                        </Col>
                      </Row>
                    </>
                  }
                />
              </Col>
            </Row>
          </Form>
          <Prompt
            when={isDirty}
            message='行った変更が保存されていない可能性があります。このページを離れますか？'
          />
        </Grid>
      </div>
    </FormProvider>
  )
}

export default StampCardCustomSettingFormView
