import React from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import Box from '@mui/material/Box'
import Barcode, { BarcodeProps } from 'react-barcode'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import FormHelperText from '@mui/material/FormHelperText'
import SelectorForm from '../../../components/FormInputs/SelectorForm'
import FormLabel from '../../../components/FormInputs/FormLabel'
import {
  BarcodeFormats,
  BarcodeValidationRules,
  CouponUsageType,
} from '../../../constants/coupon'
import { useMobileFlag } from '../../../lib/hooks'

type BarcodeFormat = NonNullable<BarcodeProps['format']>

const BARCODE_MAX_LENGTH: Partial<Record<BarcodeFormat, number>> = {
  EAN13: 13,
  CODE128: 25,
  codabar: 25,
}

const BarcodeFormatLabels: Partial<Record<BarcodeFormat, string>> = {
  EAN13: 'EAN13/JAN13',
  CODE128: 'CODE128',
  codabar: 'NW-7/codabar',
}

type Props = {
  userContentUsageMethod: string
  autoUseTimerMinutes: number
  barcodeType: BarcodeFormat
  couponCode: string
}

const CouponUsageMethodForm = ({
  userContentUsageMethod,
  autoUseTimerMinutes,
  barcodeType,
  couponCode,
}: Props): JSX.Element => {
  const isMobile = useMobileFlag()
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext()
  const barcodeFormatType = barcodeType || BarcodeFormats[0]

  const watchedCouponCode =
    (useWatch({ control, name: 'coupon.coupon_code' }) as string) || ''

  const maxLength = BARCODE_MAX_LENGTH[barcodeFormatType] ?? 25

  // フォーカス外れた時に、入力したバーコードの値をチェック
  // バーコードのフォーマットタイプによって、自動で値が入力されるものもあるので、チェックが必要
  const handleBarcodeBlur = () => {
    const formattedValue =
      BarcodeValidationRules[barcodeFormatType].format(watchedCouponCode)
    setValue('coupon.coupon_code', formattedValue, { shouldValidate: true })
  }

  const getBarcodeLabel = (format: BarcodeFormat): string =>
    BarcodeFormatLabels[format] || format

  return (
    <Box>
      <Controller
        name='coupon.user_content_usage_method'
        defaultValue={userContentUsageMethod}
        render={({ onChange, value }) => (
          <SelectorForm
            label='利用方法'
            value={value}
            options={Object.entries(CouponUsageType).map(([key, label]) => ({
              value: key,
              label,
            }))}
            onSelect={({ target }) => {
              if (target.value === 'barcode') {
                setValue('coupon.barcode_type', barcodeType)
              }
              onChange(target.value)
            }}
            width={300}
          />
        )}
      />

      {userContentUsageMethod === 'barcode' && (
        <Box>
          <Box
            display='flex'
            flexDirection={isMobile ? 'column' : 'row'}
            gap={4}
            marginBottom='20px'
          >
            <Box width={isMobile ? '100%' : '50%'}>
              <Controller
                name='coupon.barcode_type'
                defaultValue={barcodeFormatType}
                render={({ onChange, value }) => (
                  <SelectorForm
                    label='バーコードの種類'
                    value={value}
                    options={BarcodeFormats.map((format) => ({
                      value: format,
                      label: getBarcodeLabel(format),
                    }))}
                    style={{ width: 300 }}
                    onSelect={({ target }) => {
                      setValue('coupon.coupon_code', '')
                      onChange(target.value)
                    }}
                  />
                )}
              />

              <Controller
                name='coupon.coupon_code'
                defaultValue={couponCode}
                rules={{
                  required: 'バーコードの値を入力してください。',
                  validate: (value) =>
                    BarcodeValidationRules[barcodeFormatType].validate(value),
                }}
                render={({ onChange }) => (
                  <Box>
                    <FormLabel label='バーコードの値' />
                    <OutlinedInput
                      value={watchedCouponCode}
                      onChange={({ target }) => {
                        onChange(target.value)
                      }}
                      onBlur={handleBarcodeBlur}
                      inputProps={{ maxLength }}
                      style={{ width: 300 }}
                      placeholder={
                        barcodeFormatType === 'EAN13'
                          ? '12桁の数字を入力'
                          : 'バーコード値'
                      }
                    />
                    {barcodeFormatType === 'codabar' && (
                      <FormHelperText>
                        NW-7（Codabar）形式のバーコードです：
                        <br />
                        ・先頭と末尾に必ずスタート/ストップ文字A,B,C,D（a,b,c,d）のいずれかを入れてください
                        <br />
                        ・スタート/ストップ文字の間は数字と記号(-,$,:,/,.,+)のみ使用可能です
                      </FormHelperText>
                    )}
                    {errors.coupon?.coupon_code && (
                      <FormHelperText error>
                        {errors.coupon.coupon_code.message}
                      </FormHelperText>
                    )}
                  </Box>
                )}
              />

              <Controller
                name='coupon.auto_use_timer_minutes'
                defaultValue={autoUseTimerMinutes}
                rules={{
                  required: '利用後のバーコード表示時間を入力してください。',
                }}
                render={({ onChange, value }) => (
                  <Box mt={2}>
                    <FormLabel label='利用後のバーコード表示時間' />
                    <OutlinedInput
                      value={value}
                      onChange={({ target }) => {
                        onChange(Math.max(1, Number(target.value)))
                      }}
                      type='number'
                      placeholder='15'
                      sx={{ mr: 1 }}
                      style={{ width: 100 }}
                    />
                    分
                    {errors.coupon?.auto_use_timer_minutes && (
                      <FormHelperText error>
                        {errors.coupon.auto_use_timer_minutes.message}
                      </FormHelperText>
                    )}
                  </Box>
                )}
              />
            </Box>

            {watchedCouponCode && (
              <Box
                width={isMobile ? '100%' : '30%'}
                display='flex'
                flexDirection='column'
                alignItems='center'
              >
                <FormLabel label='バーコードサンプル' />
                  <Barcode
                    value={watchedCouponCode}
                    format={barcodeFormatType}
                    width={1}
                    height={50}
                    displayValue={false}
                  />
                  <Box>
                    {watchedCouponCode}
                  </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default CouponUsageMethodForm
